import React from 'react';
import { Link } from 'react-router-dom';

const AboutArea = () => {
   return (
     <>
       <section className="tp-about-area position-relative pt-120 pb-90 fix">
         <div className="tp-about-shape">
           <img
             src="assets/img/about/about-shape-1.jpg"
             className="img-fluid"
             alt="img not found"
           />
         </div>
         <div className="container">
           <div className="row justify-content-xl-between justify-content-md-center">
             <div className="col-xl-5 col-12">
               <div
                 className="tp-about-img z-index wow fadeInUp"
                 data-wow-delay=".3s"
               >
                 <img
                   src="https://drive.google.com/uc?export=download&id=1pU9xhaXeyAFgR0Gt7W7CNKswXgy4N5Oa"
                   alt="img not found"
                 />
               </div>
             </div>
             <div className="col-xl-6 col-md-10">
               <div
                 className="tp-about-text z-index wow fadeInUp"
                 data-wow-delay=".6s"
               >
                 <div className="section-title-wrapper mb-30">
                   <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                     About our Company
                   </h5>
                   <h2 className="tp-section-title">
                     We Take Pride <br />
                     in the Craftsmanship
                   </h2>
                 </div>
                 <p className="mb-40">
                   With every new project, we draw on experience from hundreds
                   of past projects to bring your kitchen into life, with the
                   best materials and craftsmanship.
                 </p>
                 <div className="row mb-10">
                   <div className="col-sm-6">
                     <div className="tp-about-service mb-30">
                       <div className="tp-about-service-icon yellow-circle-shape mb-15">
                         <i className="flaticon-snowfall"></i>
                       </div>
                       <div className="tp-about-service-text">
                         <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                           <Link to="/services">Residential Restorations</Link>
                         </h4>
                         <p>
                           We work with you one-on-one to bring your dream
                           kitchen to life.
                         </p>
                       </div>
                     </div>
                   </div>
                   <div className="col-sm-6">
                     <div className="tp-about-service mb-30">
                       <div className="tp-about-service-icon yellow-circle-shape mb-15">
                         <i className="flaticon-business-and-trade"></i>
                       </div>
                       <div className="tp-about-service-text">
                         <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                           <Link to="/services">Commercial Restoration</Link>
                         </h4>
                         <p>
                           We work on all sort of projects ranging from small to
                           large.
                         </p>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-sm-6">
                     <div className="tp-about-author mb-30">
                       <div className="tp-about-author-img">
                         <img
                           src="https://drive.google.com/uc?export=download&id=1PoTUq8SzU5mq7OBKixGoaw4t-v6q-IQc"
                           className="img-fluid"
                           alt="img not found"
                         />
                       </div>
                       <div className="tp-about-author-text">
                         <h4 className="tp-about-author-text-title">
                           Zackaria Ahmed
                         </h4>
                         <span>CEO - QCR</span>
                       </div>
                     </div>
                   </div>
                   <div className="col-sm-6">
                     <div className="tp-about-number mb-30">
                       <div className="tp-about-number-icon">
                         <i className="flaticon-phone-call-1"></i>
                       </div>
                       <div className="tp-about-number-text">
                         <span>Free Consultancy</span>
                         <a href="tel:1(929)4039206">1 (929) 403-9206</a>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </section>
     </>
   );
};

export default AboutArea;