import { Helmet } from 'react-helmet'

import Footer from '../../../components/shared/Footer/Footer'
import Navigation from '../../../components/shared/Navigation/Navigation'
import BodyComponent from './BodyComponent'
// import AboutArea from "./AboutArea/AboutArea";
// import BannerArea from "./BannerArea/BannerArea";
// import Blogs from "./Blogs/Blogs";
// import ChoseArea from "./ChoseArea/ChoseArea";
import CtaArea from './CtaArea/CtaArea'
// import FactArea from "./FactArea/FactArea";
// import PricingArea from "./PricingArea/PricingArea";
// import ProgressArea from "./ProgressArea/ProgressArea";
// import Projects from "./Projects/Projects";
// import ServiceArea from "./ServiceArea/ServiceArea";
// import Testimonial from "./Testimonial/Testimonial";
import ServicesBrandLogos from '../../Services/Services/ServicesBrandLogo/ServicesBrandLogos'
import FAQ from './FAQ'
import HeroSection from './HeroSection/HeroSection'
import Process from './Process'
import Warranty from './Warranty'
import WhyQCR from './WhyQCR'

const LandingPageOne = () => {
  return (
    <>
      <Helmet>
        <title>Tiles & Flooring Solutions | Queens Cabinet & Flooring Co.</title>
        <meta
          name='description'
          content='Transform your home with beautiful and durable flooring solutions from QCF. Our team of experts offer a wide range of services including tiling, hardwood floors, porcelain, marble and more to fit every style and budget. Contact us today to schedule a consultation.'
        />
      </Helmet>
      <Navigation landingPage={true} />
      <HeroSection />
      <BodyComponent />
      <CtaArea />
      <Process />
      <ServicesBrandLogos />
      <WhyQCR />
      <Warranty />
      <FAQ />
      <Footer landingPage={true} />
    </>
  )
}

export default LandingPageOne
