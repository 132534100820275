import { Helmet } from 'react-helmet'
import Footer from '../../../components/shared/Footer/Footer'
import HomeTwoNavbar from '../../HomeTwo/HomeTwo/HomeTwoNavbar/HomeTwoNavbar'
import SpecialPricingPackage from '../SpecialPricingPackage/SpecialPricingPackage'
import PricingBreadcrumb from './PricingBreadcrumb/PricingBreadcrumb'
const Pricing = () => {
  return (
    <>
      <Helmet>
        <title>Pricing | Premium Cabinet Restoration</title>
        <meta
          name='description'
          content='Discover our competitive pricing for flooring and cabinet restoration services. Get an accurate and transparent quote for your project. We offer quality services at affordable prices in New York, Long Island, and New Jersey.'
        />
      </Helmet>
      <HomeTwoNavbar />
      <PricingBreadcrumb />
      {/* <PricingAffordablePackage /> */}
      <SpecialPricingPackage />
      <Footer />
    </>
  )
}

export default Pricing
