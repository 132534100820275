import Footer from '../../../components/shared/Footer/Footer'
import HomeTwoNavbar from '../../HomeTwo/HomeTwo/HomeTwoNavbar/HomeTwoNavbar'
import ContactBreadcrumb from './ContactBreadcrumb/ContactBreadcrumb'
import ContactForm from './ContactForm/ContactForm'
const Contact = () => {
  return (
    <>
      {/* <Navigation /> */}
      <HomeTwoNavbar />
      <ContactBreadcrumb />
      {/* <ContactMap /> */}
      <ContactForm />
      <Footer />
    </>
  )
}

export default Contact
