import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { FaArrowAltCircleRight, FaPlay} from 'react-icons/fa';
import { CgCheckO } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { ServicesDetailsFaqArea } from './styles';
const ServicesDetailsFaq = () => {
  const [isOpen, setOpen] = useState(false);
  const sidebarLinks = [
    {
      id: 1,
      title: "Restoration",
      link: "/get-estimate",
    },
    {
      id: 2,
      title: "Refinishing",
      link: "/get-estimate",
    },
    {
      id: 3,
      title: "Refacing",
      link: "/get-estimate",
    },
    {
      id: 4,
      title: "Painting",
      link: "/get-estimate",
    },
    {
      id: 5,
      title: "Installation",
      link: "/get-estimate",
    },
  ];
  return (
    <ServicesDetailsFaqArea>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="QX8fWnmi4j4"
        onClose={() => setOpen(false)}
      />

      <section className="tp-service-details-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="tp-faqs-left">
                <div className="tp-faqs-left-sidebar tp-services-sidebar mb-30">
                  <h4 className="tp-faqs-left-sidebar-title">Request...</h4>
                  <ul>
                    {sidebarLinks.map((item, index) => {
                      return (
                        <li key={item.id}>
                          <a href={item.link}>
                            {item.title} Service
                            <i>
                              {" "}
                              <FaArrowAltCircleRight />{" "}
                            </i>{" "}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="tp-faqs-left-img mb-30">
                  <a href="/get-estimate">
                    <img
                      src="https://drive.google.com/uc?export=download&id=1Xt8Hb_UuFQIzFuh3DLjiZqrG7--xBb2f"
                      className="img"
                      alt="img not found"
                    />
                  </a>
                </div>
                {/* <div className="tp-faqs-left-sidebar tp-services-sidebar mb-30">
                  <h4 className="tp-faqs-left-sidebar-title mb-30">
                    Category...
                  </h4>
                  <div className="tp-services-sidebar-btn">
                    <a href="#" className="mb-15 text-white">
                      <i className="flaticon-pdf-file"></i> Presentation
                    </a>
                    <a href="#" className="mb-10 text-white">
                      <i className="flaticon-file"></i> Download.txt
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="tp-service-details">
                <h3 className="tp-service-details-subtitle mb-20">
                  The Heart of Your Home
                </h3>
                <p>
                  Your kitchen cabinets are not just storage places to keep your
                  dishes and other kitchen items. It is the first thing that you
                  and your guests see when they walk into the heart of your
                  home, the kitchen! Overtime your kitchen cabinets lose some of
                  their luster and the color fades, the areas around the handles
                  may be worn down from repeatedly being opened over the years,
                  and the colors can begin to just look old and gets faded.
                </p>
                <p>
                  Replacing your hardwood cabinets can be a costly and time
                  consuming,
                </p>
                <p>
                  <span className="why-choose-us-bullet">
                    Cheaper than Refacing or Replacing -
                  </span>{" "}
                  Hiring a cabinet refinishing service is a cost-effective way
                  to refresh scuffed, scratched, or faded cabinetry. Refinishing
                  costs roughly a third of refacing, and roughly a fifth of
                  replacing your cabinets.
                </p>
                <p>
                  <span className="why-choose-us-bullet">
                    Restore the Beauty of Your Cabinetry -
                  </span>{" "}
                  Even with meticulous maintenance and care, wood finish loses
                  its luster, and cabinets take on a shabby and outdated
                  appearance. But with professional refinishing, you can restore
                  your cabinets to a brand new appearance.
                </p>
                <p>
                  <span className="why-choose-us-bullet">
                    Maintain the Integrity of Wood Cabinets -
                  </span>{" "}
                  A new finish on your cabinets doesn’t just look great. It also
                  extends the lifespan of your cabinets by protecting them from
                  scratches, scuffs, food stains, water damage, and more.
                </p>
                <h3 className="tp-service-details-subtitle mb-20 why-choose-us">
                  Why Choose us
                </h3>
                <p className="mb-35"></p>
                <div className="row row-container">
                  <div className="col-xl-4 col-md-6">
                    <div className="tp-service-details-feature text-center bg-gray-light mb-40">
                      <div className="tp-feature-three-icon mb-25">
                        <img
                          src="assets/img/icon/service-feature.png"
                          className="img-fluid"
                          alt="img not found"
                        />
                        <i className="flaticon-booking"></i>
                      </div>
                      <h4 className="tp-service-details-feature-title">
                        <Link to="/get-estimate">Free Consultation</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="tp-service-details-feature text-center bg-gray-light mb-40">
                      <div className="tp-feature-three-icon mb-25">
                        <img
                          src="assets/img/icon/service-feature.png"
                          className="img-fluid"
                          alt="img not found"
                        />
                        <i className="flaticon-delivery-box"></i>
                      </div>
                      <h4 className="tp-service-details-feature-title">
                        <Link to="/get-estimate">Quick Efficent Service</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="tp-service-details-feature text-center bg-gray-light mb-40">
                      <div className="tp-feature-three-icon mb-25">
                        <img
                          src="assets/img/icon/service-feature.png"
                          className="img-fluid"
                          alt="img not found"
                        />
                        <i className="flaticon-boy"></i>
                      </div>
                      <h4 className="tp-service-details-feature-title">
                        <Link to="/get-estimate">Quality Craftsmanship</Link>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-6">
                    <div className="tp-service-details-img mb-30">
                      <img
                        src="https://drive.google.com/uc?export=download&id=17FYvKVhDZE9dou56N3oLZHQI8BQaOpjK"
                        alt="img not found"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <p className="mb-20">
                      <span className="why-choose-us-bullet">
                        Free Consultation: <br />
                      </span>
                      We understand that you may not know where start...you may
                      be asking yourself questions like; "...are my cabinets
                      restorable?", "...what colors choices are possible?",
                      "...how much will it cost?", "...how long will it take?",
                      "...will it look good when it's done?" "...should I aim to
                      refinish my cabinets or stain them?". <br /> <br /> Don't
                      worry!!! We've got you. We will use our 11 years+ of
                      experience to help you make the right choice!
                    </p>
                    <p>
                      <span className="why-choose-us-bullet">
                        Efficient & Unpaused Service:{" "}
                      </span>
                      We understand how important your kitchen is. We will work
                      with precision and unmatched efficiency to complete the
                      project on time and give you back a beautiful and new
                      kitchen as fast as possible.
                    </p>
                    <p>
                      <span className="why-choose-us-bullet">
                        Delivering Quality:{" "}
                      </span>
                      our wood-working and refinishing experience is derived
                      from refinishing hard wood floors and furnitures. We are
                      very sensitive to all details surrounding the project to
                      deliver amazing refinished cabinets.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="tp-service-details-img mb-30">
                      <img
                        src="https://drive.google.com/uc?export=download&id=1fCp_jqRyrCNpjaAOSCwcO4vxiUyvCKvM"
                        alt="img not found"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-service-details-img mb-30">
                      <img
                        src="https://drive.google.com/uc?export=download&id=1UhyWn2WajKXo7wmrPbKbkwrJ3bvcUr8b"
                        alt="img not found"
                      />
                    </div>
                  </div>
                </div>
                <h2 className="tp-section-title heading-color-black pt-10 mb-20">
                  Kings of Cabinet Refinishing
                </h2>
                <p className="mb-20">
                  Queens Cabinet Restoration Inc. is a cabinet restoration
                  company that has built its reputation by delivering quality
                  craftsmanship. We are fully licensed and insured for the
                  services that we provide so know that you are in safe hands.
                  Our contracts are simplified and detail oriented for easy
                  understanding. With our experience and knowledge of the craft,
                  you can rest easy knowing your project will not go over
                  budget. The tools and products we use are industry leading and
                  well known for their superior quality. We provide value to our
                  customers by delivering quality product and a hassle free
                  experience.
                </p>
                {/* <p className="mb-35">
                  With us, you can rest assured that the job will be done with
                  skill and precision. We are detail-oriented, responsible,
                  honest, prompt and affordable with discounts for seniors,
                  veterans, and new home purchases, businesses and customers.
                  Using the highest quality paint and materials, we aim to
                  provide flawlessly beautiful results for your dream kitchen.
                  We offer a range of professional restoration services that
                  include refinishing and refacing, installations and painting
                  cabinets. If you have a outdated kitchen in need of
                  professional restoration services, contact us today. We will
                  provide a clear and timely estimate and get the job done
                  quickly. Queens Cabinet Restoration Inc. is your reliable
                  cabinet refinishing service in Queen, NY with affordable
                  rates. Call us today for a free estimate.
                </p> */}
                {/* <h3 className="tp-service-details-subtitle">
                  Service Overview
                </h3>
                <p className="mb-40">
                  Our craftsmen employ a broad range of skills in their work,
                  many of which have been long forgotten. The arts of joinery,
                  inlay, veneer application, carving, and lathe turning are all
                  a part of our daily operations. The demands of accurate
                  recreation or preservation of hand applied finishes finds us
                  using many talents and techniques not found in most wood
                  working shops.
                </p> */}
                <div className="tp-service-details-img mb-30 position-relative">
                  <img
                    src="https://drive.google.com/uc?export=download&id=1j2qVjy-lXdhdtrXJJ_ggF-ZfViSS_JOq"
                    alt="img not found"
                  />
                  <div className="tp-service-details-img-overlay">
                    <div className="tp-service-details-img-overlay-icon">
                      <button
                        onClick={() => setOpen(true)}
                        className="venobox"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/QX8fWnmi4j4"
                      >
                        <i>
                          {" "}
                          <FaPlay className="text-white" />{" "}
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
                <p className="mb-25 d-flex">
                  {" "}
                  <i>
                    {" "}
                    <CgCheckO />{" "}
                  </i>{" "}
                  With every new project, we draw on experience from hundreds of
                  past projects to bring your kitchen into life, with the best
                  materials and craftsmanship.
                </p>
                <p className="mb-40 d-flex">
                  {" "}
                  <i>
                    {" "}
                    <CgCheckO />{" "}
                  </i>{" "}
                  Bring your outdated kitchen back to life with a cabinet
                  makeover. Get that “designer-look” at a price you can afford.
                  Call us. It’s the perfect opportunity for you to revive the
                  heart of your home.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ServicesDetailsFaqArea>
  );
};

export default ServicesDetailsFaq;