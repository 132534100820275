import React from "react";
import { WhyQCRContainer } from "./style";
import Form from "../../../components/FormEmailJS";

const Process = () => {
  return (
    <WhyQCRContainer>
      <section className="tp-feature-area-three pt-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-55 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                  Why QCR?
                </h5>
                <h2 className="tp-section-title heading-color-black">
                  All The Reasons to Choose
                  <br /> The Best In The Business
                </h2>
              </div>
            </div>
          </div>
          <div className="container why-qcr">
            <div className="why-qcr-body-container">
              <ul>
                <li>
                  <p>
                    <strong>Cheaper than Refacing or Replacing&nbsp;</strong>-
                    Hiring the professionals at QCR, is a cost-effective way to
                    refresh scuffed, scratched, or faded cabinetry. Refinishing
                    costs roughly a third of refacing, and roughly a fifth of
                    replacing your cabinets. Get in touch today to find out how
                    much you can save!
                  </p>
                </li>
                <li>
                  <p>
                    <strong> Restore the Beauty of Your Cabinetry&nbsp;</strong>
                    - Even with meticulous maintenance and care, wood finish
                    will lose its luster, and cabinets take on a shabby and
                    outdated appearance which bring the overall look of your
                    kitchen down. But with professional refinishing from QCR,
                    you can restore your cabinets and give your kitchen that
                    &ldquo;designer&rdquo; look.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Maintain the Integrity of Wood Cabinets</strong> - A
                    new finish on your cabinets doesn&rsquo;t just look great.
                    It also extends the lifespan of your cabinets by protecting
                    them from scratches, scuffs, food stains, water damage, and
                    more.
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    <strong>
                      We Provide EFFICIENT &amp; UNPAUSED Service{" "}
                    </strong>
                    - We understand how important your kitchen is. We will work
                    with precision and unmatched efficiency to complete the
                    project on time and give you back a beautiful and new
                    kitchen as fast as possible.
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    <strong>Delivering Quality with Experience </strong>- Queens
                    Cabinet Restoration Inc. is a cabinet restoration company
                    that has built its reputation by delivering quality
                    craftsmanship. We are fully licensed and insured for the
                    services that we provide so know that you are in safe hands.
                    Our contracts are simplified and detail oriented for easy
                    understanding.&nbsp; With our experience and knowledge of
                    the craft, you can rest easy knowing your project will not
                    go over budget. The tools and products we use are industry
                    leading and well known for their superior quality. We
                    provide value to our customers by delivering quality
                    craftsmanship and a hassle free experience.
                  </p>
                </li>
              </ul>
            </div>
            <div className="why-qcr-form-container">
              <Form />
            </div>
          </div>
        </div>
      </section>
    </WhyQCRContainer>
  );
};

export default Process;
