import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const AboutBreadcrumb = () => {
   return (
     <>
       <Breadcrumb
         title="The Gold"
         subTitle="Standard"
         smText="About-Us"
       ></Breadcrumb>
     </>
   );
};

export default AboutBreadcrumb;