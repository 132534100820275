import styled from 'styled-components'

export const SuccessFormWrapper = styled.div`
  .success-msg-container {
    min-width: 485px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      min-width: unset;
    }
  }

  #success-msg-text {
    margin-top: 14px;
    font-size: 18px;
    text-align: center;
  }

  #thank-you-text {
    font-size: 30px;
  }

  #success-msg-note {
    font-size: 14px;
  }

  .icon {
    font-size: 100px;
    display: block;
  }
`

export const MainFormWrapper = styled.form`
  
`
