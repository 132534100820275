import styled from "styled-components";

export const TestimonialContainer = styled.div`
  .swiper-container {
    .swiper {
      padding-bottom: 30px;
    }
  }

  .ser_form_bg {
    background: url("https://drive.google.com/uc?id=1YzH_OvvHN6Nv-wpwVQb9jvPysDC98Vij");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
