import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
// import { Swiper, SwiperSlide } from "swiper/react";
import { CgArrowLongRight,CgArrowLongLeft } from 'react-icons/cg';
import "swiper/css";
import "swiper/css/pagination"
// import Fade from 'react-reveal/Fade';
import SwiperCore, { Pagination,Navigation, Autoplay, A11y, EffectFade, EffectCards } from 'swiper';
import HomeTwoHeroSectionSingleSlide from '../../../../components/HomeTwoHeroSectionSingleSlide/HomeTwoHeroSectionSingleSlide';
import { Carousel } from 'react-bootstrap';

// install Swiper modules
SwiperCore.use([Pagination,Navigation, Autoplay, A11y, EffectFade, EffectCards]);

const HomeTwoHeroSection = () => {
   const [isOpen, setOpen] = useState(false);
   const slideData = [
     {
       bg: "https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/3.png?alt=media&token=a9a8be39-3cff-4007-b08b-f872c1ee072c",
       title: "<span>Professional</span>Flooring Solutions",
       subtitle: '"From Laminate to Tiles, We\'ve Got You Covered."',
       bgMobile:
         "https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/mobile-bg-heo-fllor.png?alt=media&token=0eb1cbd8-f17d-4fbf-bcf0-d4bb81d0f504",
     },
     {
       bg: "https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/slider-bg-2-1.jpg?alt=media&token=152e9bb6-feed-450b-857e-82532ec73921",
       title: `<span>Personalized</span> Cabinet Restoration`,
       subtitle:
         '"We can restore your antique cabinets to its original beauty."',
       bgMobile:
         "https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/slider-bg-2-1.jpg?alt=media&token=152e9bb6-feed-450b-857e-82532ec73921"
     },
     {
       bg: "https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/7.png?alt=media&token=13a61669-0efa-4c45-ad98-7f3aadf7d975",
       title: "Quality Is Our Priority",
       subtitle: '"...we can make your home look like new again."',
       bgMobile:
         "https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/7.png?alt=media&token=13a61669-0efa-4c45-ad98-7f3aadf7d975",
     },
   ];
   return (
     <>
       {
         // to enable video popup, enable model and uncomment code in "HomeTwoHeroSectionSingleSlide.js"
       }
       {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen}
            videoId="o4GuSJYSzrY" onClose={() => setOpen(false)} /> */}

       <section className="tp-slider-area fix">
         <div className="tp-slider-active swiper-container">
           <Carousel
             indicators={false}
             nextIcon={
               <div className="swiper-button-next slide-next">
                 <i>
                   <CgArrowLongRight className="long_arrow" />
                 </i>{" "}
               </div>
             }
             prevIcon={
               <div className="swiper-button-prev slide-prev">
                 <i>
                   <CgArrowLongLeft className="long_arrow" />
                 </i>{" "}
               </div>
             }
           >
             <Carousel.Item>
               <HomeTwoHeroSectionSingleSlide
                 openVideo={setOpen}
                 slideData={slideData[0]}
               />
             </Carousel.Item>
             <Carousel.Item>
               <HomeTwoHeroSectionSingleSlide
                 openVideo={setOpen}
                 slideData={slideData[1]}
               />
             </Carousel.Item>

             <Carousel.Item>
               <HomeTwoHeroSectionSingleSlide
                 openVideo={setOpen}
                 slideData={slideData[2]}
               />
             </Carousel.Item>
           </Carousel>
         </div>
       </section>
     </>
   );
};

export default HomeTwoHeroSection;