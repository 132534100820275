import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import ServiceTestimonial from '../../../../components/ServiceTestimonial/ServiceTestimonial'
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper'
import 'swiper/css/pagination'
import Form from '../../../../components/FormEmailJS'
import { TestimonialContainer } from './styles'

SwiperCore.use([Navigation, Pagination])

const ServicesTestimonials = () => {
  return (
    <TestimonialContainer>
      <section className='tp-testimonial-area-two tp-testimonial-area-four position-relative'>
        <div className='tp-testimonial-area-two-shape'></div>
        <div className='container position-relative'>
          <div className='tp-testimonial-title-wrapper pt-120'>
            <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
              Our Popular Services
            </h5>
            <h2 className='tp-section-title heading-color-black'>
              Customer Says About <br />
              Our Service
            </h2>
          </div>
        </div>
        <div className='tp-testimonial-divide'>
          <div className='tp-testimonial-two-wrapper z-index mb-0'>
            <div className='tp-testimonial-two-active swiper-container common-dots pb-40'>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                className='swiper-wrapper'
                pagination={{ clickable: true }}
                autoplay={{ delay: 6000 }}
                breakpoints={{
                  550: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
                navigation={{
                  nextEl: '.service-button-next',
                  prevEl: '.service-button-prev',
                }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                {testimonialData.map((item, index) => (
                  <SwiperSlide key={index}>
                    <ServiceTestimonial
                      imageUrl={item.imageUrl}
                      title={item.title}
                      name={item.name}
                      review={item.review}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className='swiper-pagination-testimonial slide-dots '></div>
            </div>
          </div>
          <div className='tp-testimonial-two-form tp-testimonial-two-form-four pt-120 ser_form_bg z-index'>
            <Form />
          </div>
        </div>
      </section>
    </TestimonialContainer>
  )
}

export default ServicesTestimonials

const testimonialData = [
  {
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2Fdownload%20(4).png?alt=media&token=9ef807cd-74d1-401e-9683-99a2ae42449b',
    title: 'Wood Flooring',
    name: 'Eliza Baltes',
    review:
      'Fantastic. The work came out better than I ever imagined. Highly recommend their services. Truly outstanding work product, very easy to communicate with, prompt, professional, never a worry about the work being completed.',
  },
  {
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2Fdownload%20(5).png?alt=media&token=8413cd5e-c485-4423-941b-080fc7f83e5f',
    title: 'Flooring',
    name: 'Robert Yu',
    review:
      "Zack is a refreshing perfectionist in a sea of “good enough” contractors. Zack and his team's cleanliness, professionalism, and craftsmanship are all top-notch. They did a fantastic job. I would definitely recommend his services to everyone.",
  },
  {
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2Fdownload%20(6).png?alt=media&token=f43d27f3-e6e0-4c84-954a-82aef2e5f631',
    title: 'Tile Flooring',
    name: 'Terry E. Waugh',
    review:
      'My old worn floors were transformed into beautiful new floor without incurring the high cost of replacing them.  The workers were professional and worked around our schedule.  Most of all they delivered exactly what they promised.',
  },
  {
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2Fdownload%20(7).png?alt=media&token=6a280e09-ab50-4384-b9a6-9cd014da5757',
    title: 'Cabinet Refinishing',
    name: 'Shad V. Robinson',
    review:
      'They were professional, flexible & trustworthy. We have used them for many things and would never use anyone else. The creativity and quality was top notch. I get compliments on every project they have done. I would Highly recommend!! Amazing team!!',
  },
]
