const AboutFaq = () => {
  return (
    <>
      <section className='tp-faq-area'>
        <div className='container'>
          <div className='row align-items-end'>
            <div className='col-lg-6 order-2 order-lg-1'>
              <div className='tp-about-faq-img'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/faq%2Fdownload%20(8).png?alt=media&token=677d739d-bb13-496f-8eae-3a456ef03f6b'
                  alt='img not found'
                />
              </div>
            </div>
            <div className='col-lg-6 order-1 order-lg-2'>
              <div className='tp-faq-text tp-about-faq-text pt-130 pb-120'>
                <div className='section-title-wrapper-two mb-45'>
                  <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
                    Essential Questions
                  </h5>
                  <h2 className='tp-section-title heading-color-black'>
                    Every Cleaning Related <br />
                    Answer is Here
                  </h2>
                </div>
                <div className='accordion' id='accordionExample'>
                  {faqData.map((faq, index) => (
                    <div key={index} className='accordion-item'>
                      <h2 className='accordion-header' id={`heading${faq.id}`}>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#collapse${faq.id}`}
                          aria-expanded={index === 0 ? true : false}
                          aria-controls={`#collapse${faq.id}`}
                        >
                          {faq.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${faq.id}`}
                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                        aria-labelledby={`heading${faq.id}`}
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>{faq.content}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutFaq

const faqData = [
  {
    id: 'One',
    title: 'How long does a cabinet refinishing take?',
    content: ` Refinishing your bathroom or kitchen cabinets in an
          average-sized room takes around 3 days. You won't
          be able to use your kitchen for several days during
          this time, so plan on eating out.`,
  },
  {
    id: 'Two',
    title: `Will my house be dusty and dirty from the sanding?`,
    content: `Absolutely not. We will seal all work ares so thatdust doesn’t spread throught the house.`,
  },
  {
    id: 'Three',
    title: `Is high gloss more protective and easier to clean up?`,
    content: `High gloss is more protective than all the other
          finishes such as satin, matte, and sami gloss. Cleanup
          is about the same.`,
  },
  {
    id: 'Four',
    title: `What finish do you recommend?`,
    content: `In older cabinet models,
          high gloss were used most often but satin finish gives
          the most modern look.`,
  },
  {
    id: 'Five',
    title: `Who will I be dealing with, and who will be responsible for my project?`,
    content: `A dedicated team leader will be assigned to you to
          answer your questions, take your calls, and advise you
          on the status of your project every step of the way!`,
  },
  {
    id: 'Six',
    title: `Do I need to get paint or any supplies?`,
    content: `Nope! We will come with all necessary supplies,
          including ladders. If you purchase paint through us,
          we’ll bring that with us, too.`,
  },
]
