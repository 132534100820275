import 'swiper/css'
import { ServicesBrandLogoWrapper } from './style'
const ServicesBrandLogos = () => {
  return (
    <ServicesBrandLogoWrapper>
      <div className='brand-area theme-dark-bg pt-100 pb-100'>
        <div className='container'>
          <div className='brand-active-two swiper-container'>
            <div
              class='trustpilot-widget'
              data-locale='en-US'
              data-template-id='5419b6a8b0d04a076446a9ad'
              data-businessunit-id='6358a44a0927a0fcce90fbb3'
              data-style-height='24px'
              data-style-width='100%'
              data-theme='dark'
              data-min-review-count='10'
              data-without-reviews-preferred-string-id='2'
              data-style-alignment='center'
            >
              <a
                href='https://www.trustpilot.com/review/queenscabinetrestoration.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                Trustpilot
              </a>
            </div>
          </div>
        </div>
      </div>
    </ServicesBrandLogoWrapper>
  )
}

export default ServicesBrandLogos
