import React from 'react';
import { Link } from 'react-router-dom';

const HomeTwoSingleFeature = ({ icon, title, description }) => {
  return (
    <>
      <div className="col-sm-6">
        <div className="tp-feature mb-45 wow fadeInUp" data-wow-delay=".6s">
          <div className="tp-feature-icon yellow-circle-shape mb-20">
            <i className={`flaticon-${icon}`}></i>
          </div>
          <h4 className="tp-feature-title mb-15">
            {title}
          </h4>
          <p>{description}</p>
        </div>
      </div>
    </>
  );
};

export default HomeTwoSingleFeature;