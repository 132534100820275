const images = [
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00s0s_kls4B6obSvc_0CI0t2_1200x900.jpg?alt=media&token=7c79d5ef-402d-43d3-892b-be0691f7261f',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00s0s_kls4B6obSvc_0CI0t2_1200x900.jpg?alt=media&token=7c79d5ef-402d-43d3-892b-be0691f7261f',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00q0q_l5WYaid1djy_0t20CI_1200x900.jpg?alt=media&token=add1e3ff-b1ef-4621-9a79-7a163bf31171',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00q0q_l5WYaid1djy_0t20CI_1200x900.jpg?alt=media&token=add1e3ff-b1ef-4621-9a79-7a163bf31171',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00o0o_hxawxLDJ3rc_0t20CI_1200x900.jpg?alt=media&token=82e8d753-c38b-42be-bf16-4fd8b20f4d9a',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00o0o_hxawxLDJ3rc_0t20CI_1200x900.jpg?alt=media&token=82e8d753-c38b-42be-bf16-4fd8b20f4d9a',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00n0n_1kYTt3sVzZb_0CI0CI_1200x900.jpg?alt=media&token=3ccad0b8-2a06-43ba-b394-0c5e14e5531d',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00n0n_1kYTt3sVzZb_0CI0CI_1200x900.jpg?alt=media&token=3ccad0b8-2a06-43ba-b394-0c5e14e5531d',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00k0k_5ppimsNgHF1_0t20CI_1200x900.jpg?alt=media&token=0a77440c-af00-4f1e-8605-a51e9daaf3b9',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00k0k_5ppimsNgHF1_0t20CI_1200x900.jpg?alt=media&token=0a77440c-af00-4f1e-8605-a51e9daaf3b9',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00j0j_9QUdkz51CrS_0t20CI_1200x900.jpg?alt=media&token=914cba07-36bd-41e1-9f28-c8529df575b3',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00j0j_9QUdkz51CrS_0t20CI_1200x900.jpg?alt=media&token=914cba07-36bd-41e1-9f28-c8529df575b3',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00f0f_1gaomfFSEOU_0CI0t2_1200x900.jpg?alt=media&token=f586f3cb-face-4151-b707-eaaf1ac13f08',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00f0f_1gaomfFSEOU_0CI0t2_1200x900.jpg?alt=media&token=f586f3cb-face-4151-b707-eaaf1ac13f08',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00b0b_KSmjEdLUt8_0t20CI_1200x900.jpg?alt=media&token=adda3a15-db9f-4c8b-b394-3fc0d3a56259',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00b0b_KSmjEdLUt8_0t20CI_1200x900.jpg?alt=media&token=adda3a15-db9f-4c8b-b394-3fc0d3a56259',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00O0O_k7TjE3QUuoT_0t20CI_1200x900.jpg?alt=media&token=7e25fe70-c30f-4d78-b57e-3b905de68a06',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00O0O_k7TjE3QUuoT_0t20CI_1200x900.jpg?alt=media&token=7e25fe70-c30f-4d78-b57e-3b905de68a06',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00O0O_2zyJ4JzepaB_0CI0CI_1200x900.jpg?alt=media&token=7e50f849-4e9d-4bb7-beb9-7f5b8a44ce52',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00O0O_2zyJ4JzepaB_0CI0CI_1200x900.jpg?alt=media&token=7e50f849-4e9d-4bb7-beb9-7f5b8a44ce52',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00M0M_1MrsW0Uw2EI_0t20CI_1200x900.jpg?alt=media&token=316d32b8-4208-43b4-82cf-74881d222bb5',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00M0M_1MrsW0Uw2EI_0t20CI_1200x900.jpg?alt=media&token=316d32b8-4208-43b4-82cf-74881d222bb5',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00J0J_kUxSSjeWPVn_0CI0t2_1200x900.jpg?alt=media&token=069dfbe4-5cbb-4aaa-ba9d-7f8c25d12149',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00J0J_kUxSSjeWPVn_0CI0t2_1200x900.jpg?alt=media&token=069dfbe4-5cbb-4aaa-ba9d-7f8c25d12149',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00J0J_amtyLWtMhDG_0t20CI_1200x900.jpg?alt=media&token=0160ff76-bb73-4f8b-a95b-22ad31157f13',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00J0J_amtyLWtMhDG_0t20CI_1200x900.jpg?alt=media&token=0160ff76-bb73-4f8b-a95b-22ad31157f13',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00I0I_dMF8R5F5wOj_0t20CI_1200x900.jpg?alt=media&token=70b4e21b-162a-410a-ad51-b08e00d010fe',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00I0I_dMF8R5F5wOj_0t20CI_1200x900.jpg?alt=media&token=70b4e21b-162a-410a-ad51-b08e00d010fe',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00G0G_6elPVGkydED_0CI0t2_1200x900.jpg?alt=media&token=caffe790-15a8-4115-92ad-4ee282973c0d',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00G0G_6elPVGkydED_0CI0t2_1200x900.jpg?alt=media&token=caffe790-15a8-4115-92ad-4ee282973c0d',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00F0F_gDVHp3wknPS_0CI0t2_1200x900.jpg?alt=media&token=4c1865dd-cfa3-404a-8f67-a2e573149dde',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00F0F_gDVHp3wknPS_0CI0t2_1200x900.jpg?alt=media&token=4c1865dd-cfa3-404a-8f67-a2e573149dde',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00C0C_2mIPZhEQWo6_0t20CI_1200x900.jpg?alt=media&token=b4ebca9d-46be-4a83-855c-869045407b19',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00C0C_2mIPZhEQWo6_0t20CI_1200x900.jpg?alt=media&token=b4ebca9d-46be-4a83-855c-869045407b19',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00606_aYn7QnHyrOJ_0CI0CI_1200x900.jpg?alt=media&token=63678061-4416-4ef6-bdc9-af9e7381f995',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00606_aYn7QnHyrOJ_0CI0CI_1200x900.jpg?alt=media&token=63678061-4416-4ef6-bdc9-af9e7381f995',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00606_3100VMMplYx_0t20CI_1200x900.jpg?alt=media&token=9f044096-a675-4ca3-bb49-e735535dd4ce',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00606_3100VMMplYx_0t20CI_1200x900.jpg?alt=media&token=9f044096-a675-4ca3-bb49-e735535dd4ce',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00202_fI2s5WgCc4D_0t20CI_1200x900.jpg?alt=media&token=96624a28-cdae-4af5-becb-3504db307286',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00202_fI2s5WgCc4D_0t20CI_1200x900.jpg?alt=media&token=96624a28-cdae-4af5-becb-3504db307286',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00000_brvt8tTdMJ5_0t20CI_1200x900.jpg?alt=media&token=4e1c8aff-2fde-46d3-9852-a6416b59b9af',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00000_brvt8tTdMJ5_0t20CI_1200x900.jpg?alt=media&token=4e1c8aff-2fde-46d3-9852-a6416b59b9af',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00t0t_7jMeTlos9zq_0t20CI_1200x900.jpg?alt=media&token=e5af23c0-099f-4827-95e5-31767a086699',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F00t0t_7jMeTlos9zq_0t20CI_1200x900.jpg?alt=media&token=e5af23c0-099f-4827-95e5-31767a086699',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F01414_gmnW2oAqJbW_0t20CI_1200x900.jpg?alt=media&token=ca2079cb-bd10-4d6d-92cc-c4eabfd56f78',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F01414_gmnW2oAqJbW_0t20CI_1200x900.jpg?alt=media&token=ca2079cb-bd10-4d6d-92cc-c4eabfd56f78',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F01414_kUtYWJdXty1_0t20CI_1200x900.jpg?alt=media&token=97962637-1d8c-4e6d-b1e3-aa8d50424caf',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2F01414_kUtYWJdXty1_0t20CI_1200x900.jpg?alt=media&token=97962637-1d8c-4e6d-b1e3-aa8d50424caf',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2Fhomeguide-hardwood-floor-refinishing-in-kitchen-before-and-after.jpg?alt=media&token=90d40809-c571-4ec5-a608-f729cad331a1',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/past_work%2Fhomeguide-hardwood-floor-refinishing-in-kitchen-before-and-after.jpg?alt=media&token=90d40809-c571-4ec5-a608-f729cad331a1',
  },
]

export default images
