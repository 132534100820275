import styled from "styled-components";

export const FaqWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;

  .form {
    margin-top: 50px;
  }
`;