import styled from "styled-components";

export const HomeServiceSectionContainer = styled.section`

  .service-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }

`;