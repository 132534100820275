import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const AppointmentBreadcrumb = () => {
    return (
        <>
          <Breadcrumb title="Let's Get" subTitle={"Started!"} smText="Free Estimate"/>  
        </>
    );
};

export default AppointmentBreadcrumb;