import React from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from "react-icons/fa";

const HomeTwoCtaSingleItem = ({ctaData}) => {
    return (
      <>
        <div className="col-lg-4 col-md-4">
          <div className="tp-cta-two mb-30 wow fadeInUp" data-wow-delay=".2s">
            <div className="tp-cta-two-icon">
              <i className={`flaticon-${ctaData.icon}`}></i>
            </div>
            <div className="tp-cta-two-text fix">
              <h4 className="tp-cta-two-text-title">
                {/* <Link to="/servicesDetails">{title}</Link></h4> */}
                {ctaData.title}
              </h4>
              <p>
                {ctaData.details}
              </p>
            </div>
          </div>
        </div>
      </>
    );
};

export default HomeTwoCtaSingleItem;