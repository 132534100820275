import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { BsChevronRight,BsChevronLeft } from 'react-icons/bs';
// import Swiper core and required modules
import SwiperCore, { Navigation } from 'swiper';
import AboutSingleTestimonial from '../../../../components/AboutSingleTestimonial/AboutSingleTestimonial';
import { AboutTestimonialWrapper } from './styles';
SwiperCore.use([Navigation]);


const AboutTestimonial = () => {
   const prevRef = useRef(null);
   const nextRef = useRef(null);

   const testimonialData = [
     {
       imageUrl:
         "https://drive.google.com/uc?id=1c3fVdpAjtRc9oVVJmhldT1zwjpspZNrk",
       title: "Cabinet Refinishing",
       name: "Robert Yu",
       review:
         "Zack is a refreshing perfectionist in a sea of “good enough” contractors. Zack and his team's cleanliness, professionalism, and craftsmanship are all top-notch. They did a fantastic job. I would definitely recommend his services to everyone.",
     },
     {
       imageUrl:
         "https://drive.google.com/uc?id=1OFi2WCIkdohAfguaHzMKdsv9kI_9ZGYS",
       title: "Cabinet Refinishing",
       name: "Eliza Baltes",
       review:
         "Fantastic. The work came out better than I ever imagined. Highly recommend their services. Truly outstanding work product, very easy to communicate with, prompt, professional, never a worry about the work being completed.",
     },
     {
       imageUrl:
         "https://drive.google.com/uc?id=17oqomxFautiYhQcWUr6G6jgn4LGrDkae",
       title: "Cabinet Refinishing",
       name: "Shad V. Robinson",
       review:
         "They were professional, flexible & trustworthy. We have used them for many things and would never use anyone else. The creativity and quality was top notch. I get compliments on every project they have done. I would Highly recommend!! Amazing team!!",
     },
   ];
   return (
     <AboutTestimonialWrapper>
       <section className="tp-testimonial-area position-relative">
         <div className="container">
           <div className="tp-testimonial-bg white-bg z-index">
             <div className="row align-items-center">
               <div className="col-xl-5 col-lg-6">
                 <div className="tp-testimonial-img">
                   <img
                     src="https://drive.google.com/uc?export=download&id=1ji1n61rpu5BFKjNLD0PtzFmRjHDlAqp6"
                     alt="img bot found"
                   />
                 </div>
               </div>
               <div className="col-xl-7 col-lg-6">
                 <div className="tp-testimonial ml-70">
                   <div className="section-title-wrapper">
                     <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                       Customer Statement
                     </h5>
                     <h2 className="tp-section-title mb-20">
                       Our Client Feedback
                     </h2>
                   </div>
                   <div className="tp-testimonial-active swiper-container">
                     <Swiper
                       spaceBetween={30}
                       slidesPerView={1}
                       className="swiper-wrapper"
                       autoplay={{ delay: 6000 }}
                       // pagination={{ clickable: true }}
                       // navigation={true}
                       onInit={(swiper) => {
                         swiper.params.navigation.prevEl = prevRef.current;
                         swiper.params.navigation.nextEl = nextRef.current;
                         swiper.navigation.init();
                         swiper.navigation.update();
                       }}
                       breakpoints={{
                         550: {
                           slidesPerView: 1,
                         },
                         768: {
                           slidesPerView: 1,
                         },
                         1200: {
                           slidesPerView: 1,
                         },
                       }}
                       navigation={{
                         nextEl: ".testimonial-button-next",
                         prevEl: ".testimonial-button-prev",
                       }}
                       // onSlideChange={() => console.log('slide change')}
                       // onSwiper={(swiper) => console.log(swiper)}
                     >
                        {testimonialData.map((testimonial, index) => {
                           return (
                              <SwiperSlide key={index}>
                                 <AboutSingleTestimonial
                                    imageUrl={testimonial.imageUrl}
                                    name={testimonial.name}
                                    title={testimonial.title}
                                    review={testimonial.review}
                                 />
                              </SwiperSlide>
                           )
                        })}
                       <div ref={prevRef}>Prev</div>
                       <div ref={nextRef}>Next</div>
                     </Swiper>
                   </div>
                 </div>
               </div>
             </div>

             <div className="tp-testimonial-slider-arrow">
               <div className="testimonial-button-next slide-next">
                 <i>
                   <BsChevronRight />
                 </i>
               </div>
               <div className="testimonial-button-prev slide-prev">
                 <i>
                   <BsChevronLeft />
                 </i>
               </div>
             </div>
           </div>
         </div>
         <div className="tp-testimonial-shape about"></div>
       </section>
     </AboutTestimonialWrapper>
   );
};

export default AboutTestimonial;