import { useState } from 'react'

import emailjs from '@emailjs/browser'
import ReactGA from 'react-ga4'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MainFormWrapper, SuccessFormWrapper } from './styles'

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY)

const FormSubmission_EmailJS = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [service, setService] = useState('Choose Service')
  const [details, setDetails] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setButtonDisabled(true)

    const LeadParams = {
      leadName: name,
      leadEmail: email,
      leadPhone: phone,
      leadService: service,
      leadDetails: details,
      leadUrl: window.location.pathname,
    }

    try {
      await emailjs.send('service_67ql8o4', 'LeadEmailRequestService', LeadParams)
      setFormSubmitted(true)
      setButtonDisabled(false)
      ReactGA.event({
        category: 'generate_lead',
        action: 'request_submitted',
      })
    } catch (error) {
      console.log(error)
    }
  }

  return formSubmitted ? (
    <SuccessFormWrapper id='request'>
      <div className='container success-msg-container'>
        <div className='row align-items-center'>
          <div className='col-lg-12'>
            <div className='tp-appoint-cta-content text-center'>
              <i>
                <BsFillCheckCircleFill className='icon' />{' '}
              </i>
              <h2 className='tp-appoint-cta-title'>Thank you for your interest!</h2>
              <p className='tp-appoint-cta-text'>We will be in touch shortly.</p>
            </div>
          </div>
        </div>
      </div>
    </SuccessFormWrapper>
  ) : (
    <MainFormWrapper
      onSubmit={handleSubmit}
      className='text-start tp-testimonial-two-form-wrapper'
      id='request'
    >
      <h3 className='tp-testimonial-form-title'>
        <span>Free</span> Online Estimate
      </h3>
      <p className='mb-30'>You can expect a respond within ~15 mins</p>
      <div className='input-field mb-15'>
        <input
          type='text'
          placeholder='Your Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className='input-field mb-15'>
        <div className='input-field mb-15'>
          <input
            type='email'
            placeholder='Your Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
      </div>
      <div className='input-field mb-15'>
        <div className='input-field mb-15'>
          <input
            type='phone'
            placeholder='Your Phone Number'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
      </div>
      <div className='input-field select-field-arrow mb-15'>
        <select id='lang' onChange={(e) => setService(e.target.value)} value={service}>
          <option disabled value='Choose Service'>
            Choose Service
          </option>
          <option value='wood-floors'>Wood Installation</option>
          <option value='tiles'>Tile Installation</option>
          <option value='vinyl'>Vinyl Installation</option>
          <option value='vinyl'>Laminate Installation</option>
          <option value='vinyl'>Marble Installation</option>
          <option value='cabinet-restoration'>Cabinet Restoration</option>
          <option value='other'>Other</option>
        </select>
      </div>
      <div className='input-field mb-15'>
        <textarea
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          placeholder='Please tell us a little details of your project...'
        ></textarea>
      </div>
      <div className='input-field'>
        <button type='submit' className='yellow-btn' disabled={buttonDisabled}>
          <i className='flaticon-enter'></i> Request Estimate
        </button>
      </div>
    </MainFormWrapper>
  )
}

export default FormSubmission_EmailJS
