import Form from '../../../../components/FormEmailJS'
import { FaqWrapper } from './style'
const FAQ = () => {
  return (
    <FaqWrapper>
      <section className='tp-faq-area bg-gray-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='form'>
                <Form />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='tp-faq-text pt-120'>
                <div className='section-title-wrapper-two mb-45'>
                  <h2 className='tp-section-title-two'>
                    Frequently Asked <span>Restoration</span> Questions
                  </h2>
                </div>
                <div className='accordion' id='accordionExample'>
                  {faqData.map((faq, index) => (
                    <div key={index} className='accordion-item'>
                      <h2 className='accordion-header' id={`heading${faq.id}`}>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#collapse${faq.id}`}
                          aria-expanded={index === 0 ? true : false}
                          aria-controls={`#collapse${faq.id}`}
                        >
                          {faq.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${faq.id}`}
                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                        aria-labelledby={`heading${faq.id}`}
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>{faq.content}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </FaqWrapper>
  )
}

export default FAQ

const faqData = [
  {
    id: 'Five',
    title: `What types of flooring do you offer?`,
    content: `Our services include the installation of laminate flooring, hardwood, vinyl, luxury vinyl planks, tiles, porcelain tiles, and marbles.`,
  },
  {
    id: 'Six',
    title: `How long does it take to complete a flooring or tiling project?`,
    content: `The length of a project depends on the size of the space and the type of flooring or tiling being installed. We work efficiently to get the job done as quickly as possible while ensuring a high-quality result.`,
  },
  {
    id: 'Seven',
    title: `How much does it cost to install flooring or tiles?`,
    content: `The cost of a flooring or tiling project will vary depending on the size of the space, type of flooring or tiles being installed, and any additional services needed. We provide accurate and transparent quotes to help you budget for your project.`,
  },
  {
    id: 'Eight',
    title: `Do you offer warranty on your services?`,
    content: `Yes, we offer warranty on our services to ensure your satisfaction with the work we perform.`,
  },
]
