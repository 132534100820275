import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { Fade } from 'react-reveal';
import  { HeroSectionContainer } from './style';
const HomeTwoHeroSectionSingleSlide = ({ openVideo, slideData }) => {
    return (
      <HeroSectionContainer $bgUrl={slideData.bg} $bgMobileUrl={slideData.bgMobile}>
        <div
          className="tp-single-slider tp-slider-height-two d-flex align-items-center swiper-slide"
          data-swiper-autoplay="5000"
        >
          <div className="slide-bg home_two_slide_bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tp-slider-wrapper-two text-center mt-35">
                  {/* <div className="tp-slider-video-btn-two mb-10" data-animation="fadeInUp" data-delay=".3s">
                                    <button onClick={() => openVideo(true)} className="venobox" data-autoplay="true" data-vbtype="video" href="https://youtu.be/o4GuSJYSzrY">
                                        <i > <FaPlay className="text-white" /> </i>
                                    </button>
                                </div> */}
                  <div className="tp-slider-two z-index text-center">
                    <Fade bottom>
                      <h1
                        className="tp-slider-title-two mb-35"
                        dangerouslySetInnerHTML={{ __html: slideData.title }}
                      >
                      </h1>
                      <h3 className="tp-slider-subtitle-two">
                        {slideData.subtitle}
                      </h3>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeroSectionContainer>
    );
};

export default HomeTwoHeroSectionSingleSlide;