// libraries
import React from "react";

// styles
import { LoadingScreenWrapper } from "./style";

const Loading = () => {
  return (
    <LoadingScreenWrapper
      className="LoadingScreenWrapper"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <i className="fa fa-spinner fa-spin" />
    </LoadingScreenWrapper>
  );
};

export default Loading;
