export const gtag_report_conversion = (url) => {
  var callback = function () {
    if (typeof url != 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    send_to: 'AW-10810578634/VxB7CO-HnPoYEMqt8aIo',
    event_callback: callback,
  })
  return false
}
