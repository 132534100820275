import { Helmet } from 'react-helmet'

import Footer from '../../../components/shared/Footer/Footer'
import AboutArea from '../../Home/Home/AboutArea/AboutArea'
import AboutFaq from './AboutFaq/AboutFaq'
import AboutTestimonial from './AboutTestimonial/AboutTestimonial'
import AboutBreadcrumb from './Breadcrumb/AboutBreadcrumb'
// import Breadcrumb from './Breadcrumb/AboutBreadcrumb';
import WorkingProcess from '../../Appointment/Appointment/ApointmentFeatures/AppointmentFeatures'
import HomeTwoNavbar from '../../HomeTwo/HomeTwo/HomeTwoNavbar/HomeTwoNavbar'
const About = () => {
  return (
    <>
      {/* <Navigation/> */}
      <Helmet>
        <title>About Us | Queens Cabinet Restoration Inc.</title>
        <meta
          name='description'
          content='With every new project, we draw on experience from hundreds of past projects to bring your kitchen into life, with the best materials and craftsmanship.'
        />
      </Helmet>
      <HomeTwoNavbar />
      <AboutBreadcrumb />
      <AboutArea />
      <WorkingProcess />
      <AboutFaq />
      {/* <AboutServices /> */}
      <AboutTestimonial />
      {/* <AboutBanner /> */}
      <Footer />
    </>
  )
}

export default About
