import ImageGallery from 'react-image-gallery'
import 'react-modal-video/scss/modal-video.scss'
import { Fade } from 'react-reveal'
import PHOTOS from './data'
import { HeroWrapper } from './style'
const HeroSection = () => {
  return (
    <HeroWrapper>
      <section className='tp-slider-area fix'>
        <div className='tp-slider-active home_hero_slide swiper-container common-dots'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='tp-slider-wrapper mt-60 position-relative hero-data-container'>
                  <div className='tp-slider z-index '>
                    <Fade left>
                      <h5 className='tp-slider-subtitle section__sm__title common-yellow-shape w-80 mb-35'>
                        Fast and personalized service
                      </h5>
                    </Fade>

                    <h1 className='tp-slider-title mb-20'>
                      <Fade right>New </Fade>
                      <span>
                        <Fade top>Flooring </Fade>
                      </span>
                      <Fade left> Made Easy</Fade>
                    </h1>

                    <div className='tp-slider-h4'>
                      <Fade bottom>
                        <h4>
                          Say <strong>Goodbye</strong> to Dull and Outdated Floors!
                        </h4>
                      </Fade>
                    </div>

                    <div className='btn-container'>
                      <div className='tp-header-top-info-single'>
                        <div className='tp-header-top-info-single-btn'>
                          <a href='#request' className='yellow-btn'>
                            <i className='flaticon-enter'></i> Free Estimate
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='img-container'>
                    <Fade right>
                      {/* <img
                        src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/homepage_imgs%2Fhomeguide-hardwood-floor-refinishing-in-kitchen-before-and-after.jpg?alt=media&token=d4aeb073-f218-4951-ab91-2fcdf0bae0f8'
                        className='img-fluid mt-20'
                        alt='img'
                      /> */}
                      <ImageGallery
                        items={PHOTOS}
                        thumbnailPosition={'left'}
                        showPlayButton={false}
                        lazyLoad={true}
                        autoPlay={true}
                      />
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='swiper-paginations slide-dots'></div>
        </div>
      </section>
    </HeroWrapper>
  )
}

export default HeroSection
