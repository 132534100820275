import emailjs from '@emailjs/browser'
import { useState } from 'react'
import ReactGA from 'react-ga4'

import { BsFillCheckCircleFill } from 'react-icons/bs'
import { FaEnvelopeOpen, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
import { ContactFormWrapper, SuccessFormWrapper } from './styles'

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY)

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [service, setService] = useState('Choose Service')
  const [details, setDetails] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setButtonDisabled(true)

    const LeadParams = {
      leadName: name,
      leadEmail: email,
      leadPhone: phone,
      leadService: service,
      leadDetails: details,
      leadUrl: window.location.pathname,
    }

    try {
      await emailjs.send('service_67ql8o4', 'LeadEmailRequestService', LeadParams)
      setFormSubmitted(true)
      setButtonDisabled(false)
      ReactGA.event({
        category: 'generate_lead',
        action: 'contact_form_submitted',
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContactFormWrapper>
      <section className='tp-contact-area pb-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10'>
              <div className='section-title-wrapper-two mb-50 wow fadeInUp' data-wow-delay='.2s'>
                <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
                  Get Free Estimate
                </h5>
                <h2 className='tp-section-title heading-color-black'>
                  Curious about a project? <br />
                  Don’t hesitate, contact us now!
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='row custom-mar-20'>
                <div className='col-lg-12 col-md-4 col-sm-6 custom-pad-20'>
                  <div className='tp-contact-info mb-40 wow fadeInUp' data-wow-delay='.4s'>
                    <div className='tp-contact-info-icon'>
                      <i>
                        {' '}
                        <FaMapMarkerAlt className='contact_icon' />{' '}
                      </i>
                    </div>
                    <div className='tp-contact-info-text'>
                      <h4 className='tp-contact-info-title mb-15'>Address</h4>
                      <p>
                        88-30 197th Street, 3rd Floor <br />
                        Queens, New York 11432.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-md-4 col-sm-6 custom-pad-20'>
                  <div className='tp-contact-info mb-40 wow fadeInUp' data-wow-delay='.6s'>
                    <div className='tp-contact-info-icon'>
                      <i>
                        {' '}
                        <FaPhoneAlt className='contact_icon' />{' '}
                      </i>
                    </div>
                    <div className='tp-contact-info-text'>
                      <h4 className='tp-contact-info-title mb-15'>Phone</h4>
                      <a href='tel:3475519376'>(347) 551-9376</a>
                      <a href='tel:3475519376'>(347) 551-9376</a>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-md-4 col-sm-6 custom-pad-20'>
                  <div className='tp-contact-info mb-40 wow fadeInUp' data-wow-delay='.8s'>
                    <div className='tp-contact-info-icon'>
                      <i>
                        {' '}
                        <FaEnvelopeOpen className='contact_icon' />{' '}
                      </i>
                    </div>
                    <div className='tp-contact-info-text'>
                      <h4 className='tp-contact-info-title mb-15'>Email</h4>
                      <a href='mailto:info@themepure.com'>queenscabinetrestoration@gmail.com</a>
                      <a href='mailto:sales@queenscabinetrestoration.com'>
                        sales@queenscabinetrestoration.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='tp-contact-form'>
                {formSubmitted ? (
                  <SuccessFormWrapper>
                    <div className='success-msg-container'>
                      <i>
                        <BsFillCheckCircleFill className='icon' />{' '}
                      </i>
                      <span id='thank-you-text'>Thank You</span>
                      <p id='success-msg-text'>We will get back to you shortly.</p>
                      <span id='success-msg-note'>* The form was submitted successfully *</span>
                    </div>
                  </SuccessFormWrapper>
                ) : (
                  <div className='row custom-mar-20'>
                    <div className='col-md-6 custom-pad-20'>
                      <div className='tp-contact-form-field mb-20'>
                        <input
                          type='text'
                          placeholder='Full Name'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-md-6 custom-pad-20'>
                      <div className='tp-contact-form-field mb-20'>
                        <input
                          type='email'
                          placeholder='Email Address'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-md-6 custom-pad-20'>
                      <div className='tp-contact-form-field mb-20'>
                        <input
                          type='phone'
                          placeholder='Your Phone Number'
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-md-6 custom-pad-20'>
                      <div className='tp-contact-form-field select-field-arrow mb-20'>
                        <select
                          id='lang'
                          onChange={(e) => setService(e.target.value)}
                          value={service}
                        >
                          <option disabled value='Choose Service'>
                            Choose Subject
                          </option>
                          <option value='pricing'>Price Inquiry</option>
                          <option value='restoration'>Restoration Question</option>
                          <option value='other'>Other</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-12 custom-pad-20'>
                      <div className='tp-contact-form-field mb-20'>
                        <textarea
                          value={details}
                          onChange={(e) => setDetails(e.target.value)}
                          placeholder='Your Message...'
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-md-12 custom-pad-20'>
                      <div className='tp-contact-form-field'>
                        <button
                          type='submit'
                          onClick={handleSubmit}
                          className='theme-btn text-white'
                          disabled={buttonDisabled}
                        >
                          <i className='flaticon-enter'></i> Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContactFormWrapper>
  )
}

export default ContactForm
