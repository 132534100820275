import React from 'react';
import Form from "../../../../components/FormEmailJS";
import { AppointmentAreaContainer } from "./styles";

const ApointArea = () => {
    return (
      <AppointmentAreaContainer>
        <section
          className="tp-appoint-contact-area pt-145 appoint_bg"
          data-background="assets/img/appoint/appoint-bg.jpg"
        >
          <div className="container">
            <div className="row justify-content-lg-end justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <div className="tp-appoint-contact">
                  <Form />
                </div>
              </div>
            </div>
          </div>
        </section>
      </AppointmentAreaContainer>
    );
};

export default ApointArea;