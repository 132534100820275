import Footer from '../../../components/shared/Footer/Footer'
import HomeTwoNavbar from '../../HomeTwo/HomeTwo/HomeTwoNavbar/HomeTwoNavbar'
import ServicesArea from './ServicesArea/ServicesArea'
import ServicesBrandLogos from './ServicesBrandLogo/ServicesBrandLogos'
import ServicesBreadcrumb from './ServicesBreadcrumb/ServicesBreadcrumb'
import ServicesPricing from './ServicesPricing/ServicesPricing'
import ServicesTestimonials from './ServicesTestimonial/ServicesTestimonials'

const Services = () => {
  return (
    <>
      {/* <Navigation /> */}
      <HomeTwoNavbar />
      <ServicesBreadcrumb />
      <ServicesArea />
      <ServicesTestimonials />
      <ServicesBrandLogos />
      <ServicesPricing />
      <Footer />
    </>
  )
}

export default Services
