import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../../components/shared/Footer/Footer";
import Navigation from "../../components/shared/Navigation/Navigation";
import BodyComponent from "./BodyComponent";
// import AboutArea from "./AboutArea/AboutArea";
// import BannerArea from "./BannerArea/BannerArea";
// import Blogs from "./Blogs/Blogs";
// import ChoseArea from "./ChoseArea/ChoseArea";
import CtaArea from "./CtaArea/CtaArea";
// import FactArea from "./FactArea/FactArea";
// import PricingArea from "./PricingArea/PricingArea";
// import ProgressArea from "./ProgressArea/ProgressArea";
// import Projects from "./Projects/Projects";
// import ServiceArea from "./ServiceArea/ServiceArea";
// import Testimonial from "./Testimonial/Testimonial";
import HeroSection from "./HeroSection/HeroSection";
import ServicesBrandLogos from "../Services/Services/ServicesBrandLogo/ServicesBrandLogos";
import Process from "./Process";
import WhyQCR from "./WhyQCR";
import Warranty from "./Warranty";
import FAQ from "./FAQ";

const LandingPageOne = () => {
  return (
    <>
      <Helmet>
        <title>Cabinet Restoration | The Kings of Cabinet Restoration</title>
        <meta
          name="description"
          content="Transform your outdated kitchen with our professional cabinet restoration services. Our team of experts specializes in cabinet refinishing, painting, and staining. Get that designer look at a price you can afford in New York, Long Island, and New Jersey. Contact us for a personalized and quality service."
        />
      </Helmet>
      <Navigation landingPage={true} />
      <HeroSection />
      <BodyComponent />
      <CtaArea />
      <Process />
      <ServicesBrandLogos />
      <WhyQCR />
      <Warranty />
      <FAQ />
      <Footer landingPage={true} />
    </>
  );
};

export default LandingPageOne;
