import { Helmet } from 'react-helmet'

import Footer from '../../../components/shared/Footer/Footer'
import HomeTwoNavbar from '../../HomeTwo/HomeTwo/HomeTwoNavbar/HomeTwoNavbar'
import ServicesDetailsBreadcrumb from './ServicesDetailsBreadcrumb/ServicesDetailsBreadcrumb'
import ServicesDetailsFaq from './ServicesDetailsFaq/ServicesDetailsFaq'

const ServicesDetails = () => {
  return (
    <>
      {/* <Navigation /> */}
      <Helmet>
        <title>Why Choose Us? | The Kings of Cabinet Restoration</title>
        <meta
          name='description'
          content='We are a cabinet restoration company that has
            built a reputable name for quality workmanship and superior
            services. '
        />
      </Helmet>
      <HomeTwoNavbar />
      <ServicesDetailsBreadcrumb />
      <ServicesDetailsFaq />
      <Footer />
    </>
  )
}

export default ServicesDetails
