import { ProcessContainer } from './style'
const Process = () => {
  return (
    <ProcessContainer>
      <section className='tp-feature-area-three pt-120 pb-80'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div
                className='section-title-wrapper text-center mb-55 wow fadeInUp'
                data-wow-delay='.2s'
              >
                <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
                  Our Process
                </h5>
                <h2 className='tp-section-title heading-color-black'>
                  100% Hassle-Free
                  <br /> Premium Tiling & Flooring
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='container'>
              <div className='body-part-2-container'>
                <p>
                  <strong>Queens Cabinets & Flooring Co.</strong> are the tiling and flooring
                  professionals that will work with you to give you the best home improvement
                  experience ever!
                </p>
                <p>
                  With our 11+ years of experience, you can rest easy knowing you will be getting
                  the best in the business. We have experienced all the in&rsquo;s and out&rsquo;s
                  of tiling and flooring.
                </p>
              </div>
            </div>
          </div>
          <div className='process-step-container'>
            <div className='process-steps-image-container'>
              <div className='process-steps-graphics'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/process-landingpage%2Ftile-flooring-caldwell.jpg?alt=media&token=b6648a46-c9cd-4062-bc54-447f5bb6d475'
                  alt='img not found'
                />
              </div>
              <div className='process-steps-graphics-bg'>
                <img src='assets/img/team/team-shape-1.jpg' alt='img not found' />
              </div>
            </div>
            <div className='process-steps-1'>
              <h3>Part 1: Expert Consultation</h3>
              <p>
                Your floors form the cornerstone of your interior design, enduring the trials of
                time while maintaining their allure. However, wear and tear can dim even the most
                exquisite floors, leaving your space lackluster. But fret not! We've got you covered
                with our comprehensive flooring solutions tailored to every style and budget.
              </p>
              <p>
                Our first step is to schedule a consultation with one of our experts to assess your
                current flooring situation and discuss your design preferences. During this initial
                meeting, we will take measurements and provide you with a detailed quote. We will
                also help you select the best flooring options to suit your style, budget, and
                functional needs.
              </p>
              <p>
                Bring all the questions you want to ask us and get all the confidence you need to
                move forward with your flooring project.
              </p>
            </div>
          </div>
          <div className='process-step-container'>
            <div className='process-steps-2'>
              <div className='tp-faq-text pt-120'>
                <h3>Part 2: Time to Start the Restoration!</h3>

                <div className='accordion' id='accordionExample'>
                  {faqData.map((faq, index) => (
                    <div key={index} className='accordion-item'>
                      <h2 className='accordion-header' id={`heading${faq.id}`}>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#collapse${faq.id}`}
                          aria-expanded={index === 0 ? true : false}
                          aria-controls={`#collapse${faq.id}`}
                        >
                          {faq.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${faq.id}`}
                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                        aria-labelledby={`heading${faq.id}`}
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>{faq.content}</div>
                      </div>
                    </div>
                  ))}
                </div>

                <p>
                  Once the installation is complete, we will perform a final inspection to ensure
                  that you are 100% satisfied with the result. So why wait? Let us help you create
                  the beautiful and durable floors of your dreams. Fill out our form or give us a
                  call at{' '}
                  <strong>
                    <a href='tel:1(347)5519376'>(347) 551-9376</a>
                  </strong>{' '}
                  to schedule your consultation today.
                </p>
              </div>
            </div>
            <div className='process-steps-image-container'>
              <div className='process-steps-graphics-2'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/process-landingpage%2FTILE10.jpg?alt=media&token=83b121ed-817a-4466-b487-883581d6a6b5'
                  alt='img not found'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ProcessContainer>
  )
}

export default Process

const faqData = [
  {
    id: 'Five',
    title: `Tiling`,
    content: `Our experts work magic with ceramic, porcelain, and more to bring your vision to life.`,
  },
  {
    id: 'Six',
    title: `Hardwood Flooring`,
    content: `Experience the timeless allure and warmth of hardwood with our refinishing, restoration, and installation services.`,
  },
  {
    id: 'One',
    title: 'Porcelain Flooring',
    content: `Mimic the elegance of various materials like stone and wood with durable and versatile porcelain options.`,
  },
  {
    id: 'Two',
    title: `Marble Flooring`,
    content: `Elevate your space with the sophistication of marble through our installation, maintenance, and repair services.`,
  },
  {
    id: 'Three',
    title: `Vinyl Flooring`,
    content: `Versatility meets affordability in a wide range of styles, offering durability and easy maintenance.`,
  },
  {
    id: 'Four',
    title: `Laminate Flooring`,
    content: `Achieve the look of hardwood or tile without compromising your budget, thanks to its durability and versatility.`,
  },
]
