import styled from 'styled-components'

export const NavigationWrapper = styled.nav`
  .title {
    text-transform: uppercase;
    color: white;
    background: #064e2a;
    display: inline-block;
    padding: 20px;
  }

  .tp-header-top {
    padding-bottom: 20px;
  }

  .header-logo {
    img {
      height: 88px;
    }
  }

 

  .tp-sticky-column {
    justify-content: space-between;

    .tp-header-top-info-single-icon {
      display: none;
    }
  }

  .tp-header-area > :last-child {
    background: #272222;
  }

  @media (min-width: 1200px) {
    .justify-content-xl-center {
      justify-content: space-between !important;
    }
  }

  @media (max-width: 768px) {
    .sticky-menu {
      display: none;
    }
    .tp-sticky-column {
      width: 100%;
    }

    .tp-sticky-logo {
      width: 100%;

      img {
        max-height: 82px;
        width: auto;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tp-header-top-info-single-icon {
        display: block;
      }
    }
  }


`
