import { Helmet } from 'react-helmet'

import Footer from '../../../components/shared/Footer/Footer'
import HomeTwoNavbar from '../../HomeTwo/HomeTwo/HomeTwoNavbar/HomeTwoNavbar'
import ApointArea from './ApointArea/ApointArea'
import AppointmentFeatures from './ApointmentFeatures/AppointmentFeatures'
import AppointmentBreadcrumb from './AppointmentBreadcrumb/AppointmentBreadcrumb'
import AppointmentFeedback from './AppointmentFeadback/AppointmentFeadback'
const Appointment = () => {
  return (
    <>
      {/* <Navigation/> */}
      <Helmet>
        <title>Get A Free Estimate | Premium Cabinet Refinishing</title>
        <meta
          name='description'
          content="It's easy to get a free estimate for your cabinet refinishing project. Just fill out the form below and we'll get back to you as soon as possible."
        />
      </Helmet>
      <HomeTwoNavbar />
      <AppointmentBreadcrumb />
      <AppointmentFeatures />
      <ApointArea />
      <AppointmentFeedback />
      <Footer />
    </>
  )
}

export default Appointment
