import { BsBrush } from 'react-icons/bs'
import { FaCube, FaDiagnoses, FaHammer } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import HomeTwoSingleService from '../../../../components/HomeTwoSingleService/HomeTwoSingleService'
import { HomeServiceSectionContainer } from './style'

const HomeTwoServices = () => {
  const services = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/tiles-option.png?alt=media&token=2b1fd408-e1c9-40dc-9748-8148c899371b',
      icon: FaDiagnoses,
      title: 'Consultation',
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/woodfloor-install.png?alt=media&token=859de113-cee8-4643-96b4-7d3c8b246c45',
      icon: FaHammer,
      title: 'Flooring',
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/cabinets-gray.png?alt=media&token=ad61a754-ac99-49dc-85c2-fae94d12b6a9',
      icon: FaCube,
      title: 'Cabinets',
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/painting.png?alt=media&token=7045b01a-46a4-4520-b8e9-31e4d80ca333',
      icon: BsBrush,
      title: 'Painting',
    },
  ]
  return (
    <HomeServiceSectionContainer>
      <section className='tp-quality-service-area pt-110 bg-gray-light pb-115'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='section-title-wrapper text-center mb-55' data-wow-delay='.2s'>
                <h2 className='tp-section-title-two'>
                  Guranteed <span>Quality</span> Restoration{''}
                  <br />
                  and Flooring Everytime
                </h2>
              </div>
            </div>
          </div>
          <div className='row mb-35'>
            <HomeTwoSingleService serviceData={services[0]} />
            <HomeTwoSingleService serviceData={services[1]} />
            <HomeTwoSingleService serviceData={services[2]} />
            <HomeTwoSingleService serviceData={services[3]} />
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='tp-quality-btn text-center'>
                <Link to='/servicesDetails' className='theme-btn radius-50 text-white'>
                  More Services +
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HomeServiceSectionContainer>
  )
}

export default HomeTwoServices
