import React from "react";
// import { Link } from "react-router-dom";
import Form from "../../../components/FormEmailJS"
import { BodyWrapper } from "./style";
const BodyComponent = () => {
  return (
    <BodyWrapper>
      <section className="tp-about-area position-relative pt-20 pb-20 fix">
        <div className="container">
          <div className="body-container">
            <p>
              Your cabinets are not just storage places to keep your dishes and
              Tupperware,
              <strong>
                {" "}
                It is the first thing your guests see when they walk into the
                heart of your home{" "}
              </strong>{" "}
              &mdash; the kitchen!
            </p>
            <p>
              Over time your kitchen cabinets can lose some of their luster; the
              color usually fades and the areas around the handles begin to wear
              down from everyday use &mdash; making the whole kitchen look old
              and inadequate.
            </p>
            <p>
              It gets so bad sometimes that it makes you want to demolish your
              entire kitchen with your own hands and start over!!!
            </p>
            <p>But is that the best option? Is that an affordable option?</p>
            <p>
              Full renovation of a kitchen can easily cost you north of $40,000
              - $50,000.
            </p>
            <p>
              <strong>You do have better options; </strong>you can replace the
              cabinets or refinish your cabinets (...or if you prefer
              re-painting your cabinets to a color of your choosing**)
            </p>
            <p>
              Replacing your hardwood cabinets can be costly, and time
              consuming.
            </p>
            <p>
              If you are among those who paid a good chunk of change $$$ for
              your current hardwood cabinets, it makes no sense to replace them
              when they can easily be restored to look like new again.
            </p>
            <p>
              You can even refinish them and stain them to a different color
              that will keep the wood grains intact.
            </p>
            <p>
              &hellip;or if you prefer, you can paint your cabinets to a color
              of your choosing.
            </p>
            <p>
              In most cases the better and more suitable option is to refinish
              or repaint your cabinets. Going this route, you can save upto 80%
              OFF what you would pay if you choose to replace your cabinets.
            </p>
            <p>
              Let me paint the picture clearer for you, installing new cabinets
              can cost you well above $25,000, that means{" "}
              <strong>
                you could be saving upto $20,000 dollars by refinishing.
              </strong>
            </p>
            <p>Now that&rsquo;s a huge saving!!!</p>
            <p>
              &hellip;but again, restoration is not meant for every kitchen
              cabinetry.{" "}
            </p>
            <p>
              <strong>
                To find out if refinishing is right for you fill up this form or
                call: <a href="tel:1(347)5519376">(347) 551-9376</a>
              </strong>
            </p>
          </div>
          <div className="formContainer">
            <Form />
          </div>
        </div>
      </section>
    </BodyWrapper>
  );
};

export default BodyComponent;
