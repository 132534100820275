import styled from "styled-components";

export const ServicesBrandLogoWrapper = styled.div`
  overflow-x: hidden;
  @media (min-width: 700px) {
    .trustpilot-widget {
      transform: scale(1.5);
    }
  }
  @media (min-width: 1200px) {
    .trustpilot-widget {
      transform: scale(2.5);
    }
  }
`;