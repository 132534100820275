const HomeTwoFaq = () => {
  return (
    <>
      <section className='tp-faq-area bg-gray-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='tp-faq-img'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/faq%2Fdownload%20(8).png?alt=media&token=677d739d-bb13-496f-8eae-3a456ef03f6b'
                  alt='img not found'
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='tp-faq-text pt-120'>
                <div className='section-title-wrapper-two mb-45'>
                  <h2 className='tp-section-title-two'>
                    Frequently Asked <span>Restoration</span> Questions
                  </h2>
                </div>
                <div className='accordion' id='accordionExample'>
                  {faqData.map((faq, index) => (
                    <div key={index} className='accordion-item'>
                      <h2 className='accordion-header' id={`heading${faq.id}`}>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#collapse${faq.id}`}
                          aria-expanded={index === 0 ? true : false}
                          aria-controls={`#collapse${faq.id}`}
                        >
                          {faq.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${faq.id}`}
                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                        aria-labelledby={`heading${faq.id}`}
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>{faq.content}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomeTwoFaq

const faqData = [
  {
    id: 'Five',
    title: `What types of flooring do you offer?`,
    content: `Our services include the installation of laminate flooring, hardwood, vinyl, luxury vinyl planks, tiles, porcelain tiles, and marbles.`,
  },
  {
    id: 'Six',
    title: `How long does it take to complete a flooring or tiling project?`,
    content: `The length of a project depends on the size of the space and the type of flooring or tiling being installed. We work efficiently to get the job done as quickly as possible while ensuring a high-quality result.`,
  },
  {
    id: 'One',
    title: 'How long does a cabinet refinishing take?',
    content: ` Refinishing your bathroom or kitchen cabinets in an
          average-sized room takes around 3 days. You won't
          be able to use your kitchen for several days during
          this time, so plan on eating out.`,
  },
  {
    id: 'Two',
    title: `Will my house be dusty and dirty from the sanding?`,
    content: `Absolutely not. We will seal all work areas so that dust doesn’t spread throught the house.`,
  },
  {
    id: 'Three',
    title: `Is high gloss more protective and easier to clean up?`,
    content: `High gloss is more protective than all the other
          finishes such as satin, matte, and sami gloss. Cleanup
          is about the same.`,
  },
  {
    id: 'Four',
    title: `What finish do you recommend?`,
    content: `In older cabinet models,
          high gloss were used most often but satin finish gives
          the most modern look.`,
  },
  {
    id: 'Seven',
    title: `How much does it cost to install flooring or tiles?`,
    content: `The cost of a flooring or tiling project will vary depending on the size of the space, type of flooring or tiles being installed, and any additional services needed. We provide accurate and transparent quotes to help you budget for your project.`,
  },
  {
    id: 'Eight',
    title: `Do you offer warranty on your services?`,
    content: `Yes, we offer warranty on our services to ensure your satisfaction with the work we perform.`,
  },
]
