import React, { useState } from 'react';
import 'react-modal-video/scss/modal-video.scss';
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { HeroWrapper } from "./style";


const HeroSection = () => {

   return (
     <HeroWrapper>
       <section className="tp-slider-area fix">
         <div className="tp-slider-active home_hero_slide swiper-container common-dots">
           <div className="container">
             <div className="row">
               <div className="col-12">
                 <div className="tp-slider-wrapper mt-60 position-relative hero-data-container">
                   <div className="tp-slider z-index">
                     <Fade left>
                       <h5 className="tp-slider-subtitle section__sm__title common-yellow-shape w-80 mb-35">
                         Fast and efficient service
                       </h5>
                     </Fade>

                     <h1 className="tp-slider-title mb-20">
                       <Fade right>Are Your</Fade>
                       <span>
                         <Fade top>Kitchen Cabinets</Fade>
                       </span>
                       <Fade left>Outdated?</Fade>
                     </h1>

                     <div className="tp-slider-h4">
                       <Fade bottom>
                         <h4>
                           STRESS NO MORE!{" "}
                           <strong>Queens Cabinet Restoration</strong>, the
                           premier cabinet professionals are here for you!
                         </h4>
                       </Fade>
                     </div>
                   </div>
                   <div className="img-container">
                     <Fade right>
                       <img
                         src="assets/img/landing/img-2.png"
                         className="img-fluid mt-20"
                         alt="img"
                       />
                     </Fade>
                   </div>
                 </div>
               </div>
             </div>
           </div>
           <div className="swiper-paginations slide-dots"></div>
         </div>
       </section>
     </HeroWrapper>
   );
};

export default HeroSection;