import { Link } from 'react-router-dom'
import { AboutContainer } from './style'

const HomeTwoAbout = () => {
  return (
    <AboutContainer>
      <section className='tp-about-area-two fix pt-120 pb-140'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-10'>
              <div
                className='tp-about-img-two position-relative mr-80 wow fadeInUp'
                data-wow-delay='.4s'
              >
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/homepage_imgs%2Fdownload%20(1).png?alt=media&token=16d2905d-1f22-4e2c-bd9a-f7bd3cebba2d'
                  className='img-fluid'
                  alt='img not found'
                />
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/homepage_imgs%2Fdownload.png?alt=media&token=38ea9eb0-3df6-40f7-8867-a016490ac8e8'
                  className='img-fluid img-second'
                  alt='img not found'
                />
                <div className='tp-about-img-two-badge'>
                  <h3>11</h3>
                  <h5>
                    years <br />
                    Experience
                  </h5>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='tp-about-text-two wow fadeInUp' data-wow-delay='.7s'>
                <div className='section-title-wrapper mb-20'>
                  <h2 className='tp-section-title-two translate-y--10'>
                    <span>Brightening</span> Your Home From Every Side
                  </h2>
                </div>
                <p className='mb-40'>
                  Upgrade Your Home's Style with a Flooring and Cabinet Revamp. Get the Designer
                  Look You Want at a Price You Can Afford. Call Us Today and Refresh the Heart of
                  Your Home.
                </p>
                <div className='tp-about-text-two-service'>
                  <div className='tp-about-text-two-service-single mb-65'>
                    <div className='tp-about-text-two-service-single-icon'>
                      <span>
                        <i className='flaticon-cleaning-1'></i>
                      </span>
                    </div>
                    <div className='tp-about-text-two-service-single-text'>
                      <h4 className='tp-about-text-two-service-title'>
                        <Link to='/servicesDetails'>Expert Craftsmanship</Link>
                      </h4>
                      <p>
                        We pride ourselves on the quality of our work, care, attention, in our
                        ability to fulfill the needs of our clients.
                      </p>
                    </div>
                  </div>
                  <div className='tp-about-text-two-service-single mb-60'>
                    <div className='tp-about-text-two-service-single-icon'>
                      <span>
                        <i className='flaticon-shuttle'></i>
                      </span>
                    </div>
                    <div className='tp-about-text-two-service-single-text'>
                      <h4 className='tp-about-text-two-service-title'>
                        <Link to='/servicesDetails'>Fast Communication</Link>
                      </h4>
                      <p>
                        We would love to hear from you and answer any questions you might have.
                        Contact us today!
                      </p>
                    </div>
                  </div>
                </div>
                <div className='tp-about-text-two-sign pt-10'>
                  <div className='tp-about-text-two-sign-img'>
                    <img
                      src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/homepage_imgs%2Fdownload%20(3).png?alt=media&token=7eed0537-f711-47cc-9c45-e573168b7a13'
                      className='img-fluid'
                      alt='img not found'
                    />
                  </div>
                  <div className='tp-about-text-two-sign-text'>
                    <h4 className='tp-about-text-two-sign-name'>Zackaria Ahmed</h4>
                    <span>CEO & Co-Founder</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AboutContainer>
  )
}

export default HomeTwoAbout
