import Countdown from 'react-countdown'
import { BsInstagram, BsPinterest } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

import { FaEnvelopeOpen, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
import { WarrantyContainer } from './style'
const Warranty = () => {
  return (
    <WarrantyContainer>
      <section className='tp-team-area pt-60 pb-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='tp-team-bg theme-dark-bg'>
                <div className='tp-team fix'>
                  <div className='tp-team-img'>
                    <img
                      src='assets/img/landing/img-4.png'
                      className='img-fluid'
                      alt='img not found'
                    />
                  </div>
                  <div className='tp-team-text pt-30 fix'>
                    <h2 className='tp-section-title text-white'>That's right!</h2>
                    <h4 className='tp-team-subtitle mb-25'>Buy with Confidence</h4>
                    <p className='mb-35'>
                      We are so confident you will love our work so much that we are offering{' '}
                      <strong>1-YEAR WARRANTY</strong> on all our restoration projects. You read
                      that right, all our restorations are fully warranted against any defects in
                      materials and workmanship for 12 months.{' '}
                      <span id='disclaimer-note'>Exclusions Apply. </span>
                    </p>
                    <ul className='pb-20'>
                      <li>
                        <a href='tel:3475519376'>
                          <i>
                            {' '}
                            <FaPhoneAlt />{' '}
                          </i>{' '}
                          1 (347) 551-9376
                        </a>
                      </li>
                      <li>
                        <i>
                          {' '}
                          <FaMapMarkerAlt />{' '}
                        </i>{' '}
                        Serving The Tri-State Area
                      </li>
                      <li>
                        <a href='mailto:queenscabinetrestoration@gmail.com'>
                          <i>
                            <FaEnvelopeOpen />
                          </i>{' '}
                          queenscabinetrestoration@gmail.com
                        </a>
                      </li>
                    </ul>
                    <div className='sidebar__menu--social tp-team-social team_details_social'>
                      <a
                        href='https://www.facebook.com/queenscabinetrestoration'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {' '}
                        <i>
                          <FaFacebookF className='icon' />{' '}
                        </i>{' '}
                      </a>
                      <a
                        href='https://www.pinterest.com/queenscabinetrestoration/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {' '}
                        <i>
                          <BsPinterest className='icon' />{' '}
                        </i>
                      </a>
                      <a
                        href='https://www.instagram.com/queenscabinetrestoration/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {' '}
                        <i>
                          <BsInstagram className='icon' />{' '}
                        </i>{' '}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='pt-90'>
            <div className='discount-container'>
              <div className='row'>
                <div className='col-12'>
                  <div
                    className='section-title-wrapper text-center mb-55 wow fadeInUp'
                    data-wow-delay='.2s'
                  >
                    <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
                      Still Not Convinced?! 😱
                    </h5>
                    <h2 className='tp-section-title heading-color-black'>
                      For A Limited Time Only <br />
                      Get <span id='discount'>10% OFF</span> Your Next Project
                    </h2>
                    <h3 id='timer-container'>
                      EXPIRES IN: <Countdown date={new Date('03/15/2023')} /> DAYS
                    </h3>
                    <p>
                      We are offering <strong>10% OFF</strong> on all cabinet restoration projects,
                      upto $750. Now that's a good deal, but act fast because this offer ends on Feb
                      15th, 2023. Call:{' '}
                      <strong>
                        <a href='tel:1(347)5519376'>(347) 551-9376</a>
                      </strong>{' '}
                      or fill up this form below to claim your offer!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WarrantyContainer>
  )
}

export default Warranty
