import { Offcanvas } from 'react-bootstrap'
import Collapsible from 'react-collapsible'
import { BsInstagram, BsPinterest } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { SidebarContainer } from './style'

const Sidebar = ({ show, handleClose }) => {
  const Home = <NavLink to='/home'>Home</NavLink>
  const Pages = <NavLink to='/'>Pages </NavLink>
  const Services = <NavLink to='/services'>Services </NavLink>
  const Projects = <NavLink to='/projects'>Projects</NavLink>
  const About = <NavLink to='/servicesDetails'>Why QCR?</NavLink>

  return (
    <SidebarContainer>
      <div className='side__bar'>
        <Offcanvas show={show} onHide={handleClose} placement='end'>
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='text-white font-bold' style={{ marginBottom: '10px' }}>
              <NavLink className='font-bold' to='/'>
                Home
              </NavLink>
            </div>
            <div className='text-white font-bold' style={{ marginBottom: '10px' }}>
              <NavLink className='font-bold' to='/get-estimate'>
                Free Estimate
              </NavLink>
            </div>
            <div className='text-white font-bold' style={{ marginBottom: '10px' }}>
              <NavLink className='font-bold' to='/pricing'>
                Pricing
              </NavLink>
            </div>
            <div className='text-white font-bold' style={{ marginBottom: '10px' }}>
              <NavLink className='font-bold' to='/about'>
                About
              </NavLink>
            </div>

            <Collapsible
              trigger={About}
              triggerTagName='div'
              triggerOpenedClassName='icon_close'
              triggerClassName='iconAdd'
              open={false}
            >
              <ul className='sidebar_sub_menu text-white mt-3'>
                <li>
                  <NavLink to='/about'>About</NavLink>
                </li>
                <li>
                  <NavLink to='/servicesDetails'>Why QCR?</NavLink>
                </li>
              </ul>
            </Collapsible>

            {/* <Collapsible
               trigger={Services}
               triggerTagName="div"
               triggerOpenedClassName="icon_close"
               triggerClassName="iconAdd"
               open={false}
             >
               <ul className="sidebar_sub_menu text-white mt-3">
                 <li>
                   <NavLink to="/services">Services</NavLink>
                 </li>
                 <li>
                   <NavLink to="/servicesDetails">Services Details</NavLink>
                 </li>
               </ul>
             </Collapsible> */}

            <div className='contact_nav text-white font-bold'>
              <NavLink className='font-bold' to='/contact'>
                Contact
              </NavLink>
            </div>

            <div className='fix'>
              <div className='side-info'>
                <div className='side-info-content'>
                  <div className='tp-mobile-menu'></div>
                  <div className='contact-infos mb-30'>
                    <div className='contact-list mb-30'>
                      <h4>Contact Info</h4>
                      <ul>
                        <li>
                          <i className='flaticon-pin'></i>Queens, New York
                        </li>
                        <li>
                          <i className='flaticon-email'></i>
                          <a href='mailto:queenscabinetrestoration@gmail.com'>Click to Email</a>
                        </li>
                        <li>
                          <i className='flaticon-phone-call'></i>
                          <a href='tel:3475519376'>(347) 551-9376</a>
                        </li>
                      </ul>
                      <div className='sidebar__menu--social'>
                        <a
                          className='text-white'
                          href='https://www.facebook.com/queenscabinetrestoration/'
                        >
                          <i>
                            <FaFacebookF className='icon' />{' '}
                          </i>
                        </a>
                        <a
                          className='text-white'
                          href='https://www.pinterest.com/queenscabinetrestoration/'
                        >
                          <i>
                            <BsPinterest className='icon' />{' '}
                          </i>
                        </a>
                        <a
                          className='text-white'
                          href='https://www.instagram.com/queenscabinetrestoration/'
                        >
                          <i>
                            <BsInstagram className='icon' />{' '}
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </SidebarContainer>
  )
}

export default Sidebar
