import styled from 'styled-components'

export const ProcessContainer = styled.div`
  .body-part-2-container {
    position: relative;
    z-index: 1;
    p {
      color: #000;
    }
    padding: 0 15px 0 0;

    @media (min-width: 778px) {
      text-align: center;
    }
  }

  .process-step-container {
    display: flex;
    padding: 10px;
    position: relative;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;

      &.process-steps-2 {
        margin-right: 20px;
        .accordion {
          margin-bottom: 20px;

        }
      }
    }

    margin-top: 50px;
    h3,
    p {
      color: #000;
    }
    @media (max-width: 778px) {
      flex-direction: column;

      .process-steps-graphics-2 {
        padding: 0;
      }
    }
  }

  .process-steps-1 {
    padding-right: 40px;
  }

  .process-steps-image-container,
  .process-steps-image-container-2 {
    position: relative;
    @media (max-width: 778px) {
      padding-bottom: 50px;
    }
  }

  .process-steps-graphics-bg {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 70%;

    @media (max-width: 778px) {
      display: none;
    }
  }

  .process-steps-graphics {
    position: relative;
    z-index: 1;
    padding: 50px;

    @media (max-width: 778px) {
      padding: 0;
    }
  }

  .process-steps-2 {
    @media (max-width: 778px) {
      order: 2;
    }
  }

  .process-steps-image-container-2 {
    order: 1;
  }
  .process-steps-graphics-2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    @media (min-width: 778px) {
      margin-top: 50px;
      padding: 0;
    }
  }

  .list-item {
    margin-left: 20px;
  }
`
