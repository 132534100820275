import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { SuperSEO as SEO } from 'react-super-seo'
import { WOW } from 'wowjs'
import './App.css'
import ScrollToTop from './components/ScrollToTop'
import About from './pages/About/About/About'
import Appointment from './pages/Appointment/Appointment/Appointment'
import CabinetRestoration from './pages/CabinetRestoration/'
import Contact from './pages/Contact/Contact/Contact'
import Faq from './pages/Faq/Faq/Faq'
import HomeTwo from './pages/HomeTwo/HomeTwo/HomeTwo'
import NotFound from './pages/NotFound/NotFound.js'
import Pricing from './pages/Pricing/Pricing/Pricing'
import Services from './pages/Services/Services/Services'
import ServicesDetails from './pages/ServicesDetails/ServicesDetails/ServicesDetails'
import TilingFlooring from './pages/TilingFlooring/page-1'
import TilingFlooringPageTwo from './pages/TilingFlooring/page-2'
const UsePageViews = () => {
  let location = useLocation()
  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [location])
  return <></>
}

const App = () => {
  useEffect(() => {
    const wow = new WOW()
    wow.init()
  }, [])

  return (
    <>
      <BrowserRouter>
        <UsePageViews />
        <SEO
          title={SEOParams.title}
          description={SEOParams.description}
          lang='en'
          openGraph={{
            ogImage: {
              ogImage: SEOParams.image,
              ogImageAlt: 'Queens Cabinet Experts',
              ogImageWidth: 1200,
              ogImageHeight: 630,
              ogImageType: 'image/jpeg',
            },
          }}
          twitter={{
            twitterSummaryCard: {
              summaryCardImage: SEOParams.image,
              summaryCardImageAlt: 'Queens Cabinet Experts',
              summaryCardSiteUsername: 'RestoreCabinets',
            },
          }}
        />
        <ScrollToTop />
        <Routes>
          <Route path='/' exact element={<HomeTwo />} />
          <Route path='/home' element={<HomeTwo />} />
          <Route path='/about' element={<About />} />
          <Route path='/get-estimate' element={<Appointment />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/services' element={<Services />} />
          <Route path='/servicesDetails' element={<ServicesDetails />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/cabinet-restoration' element={<CabinetRestoration />} />
          <Route path='/tiling-and-flooring' element={<TilingFlooring />} />
          <Route path='/tiles-and-flooring' element={<TilingFlooringPageTwo />} />
          <Route path='*' element={<NotFound />} status={404} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App

const SEOParams = {
  title: 'Queens Cabinet & Flooring Co.',
  description:
    'Get the best flooring and cabinet restoration services in New York, Long Island, and New Jersey. We offer a wide range of flooring options, including laminate, hardwood, vinyl, tiles, and more. Contact us for a personalized and quality service that prioritizes customer satisfaction.',
  image:
    'https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/blue-logo.png?alt=media&token=de0f73c5-925f-48b6-930d-62d87f5a1fde',
}
