import { useState } from 'react'
import { BsInstagram, BsPinterest } from 'react-icons/bs'
import { FaBars, FaFacebookF } from 'react-icons/fa'
import { Link, NavLink } from 'react-router-dom'
import Sidebar from '../../../../components/Sidebar/Sidebar'
import { NavbarContainer } from './style'
const HomeTwoNavbar = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [sticky, setSticky] = useState(false)
  const stickyNavbar = () => {
    if (window.scrollY > 80) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }
  window.addEventListener('scroll', stickyNavbar)
  return (
    <>
      <NavbarContainer>
        <div
          className={
            sticky
              ? 'sticky-menu tp-header-area-two header-sticky'
              : 'tp-header-area-two header-sticky'
          }
        >
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-xl-3 col-8'>
                <div className='tp-header-logo-two'>
                  <div
                    className='tp-header-logo-two-inner'
                    data-background='assets/img/logo/logo-white-bg.png'
                  >
                    <Link to='/'>
                      <img
                        id='header-nav-logo'
                        src='assets/img/logo/logo-white.png'
                        alt='img not found'
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-xl-9 col-4'>
                <div className='tp-header-menu-two-wrapper'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='tp-header-top-two'>
                        <div className='tp-header-top-two-info'>
                          <ul>
                            <li>
                              <i className='flaticon-pin'></i> Queens, New York
                            </li>
                            <li>
                              <i className='flaticon-email'></i>
                              <a href='mailto:queenscabinetrestoration@gmail.com'>
                                queenscabinetrestoration@gmail.com
                              </a>
                            </li>
                            <li className='tp-desktop-header-phone-icon-container'>
                              <i className='flaticon-phone-call'></i>
                              <a href='tel:1(347)5519376'>(347) 551-9376</a>
                            </li>
                          </ul>
                        </div>
                        <div className='tp-header-top-two-social'>
                          <a
                            href='https://www.facebook.com/queenscabinetrestoration'
                            target='_blank'
                            rel='noreferrer'
                          >
                            {' '}
                            <i>
                              <FaFacebookF className='icon' />{' '}
                            </i>{' '}
                          </a>
                          <a
                            href='https://www.pinterest.com/queenscabinetrestoration/'
                            target='_blank'
                            rel='noreferrer'
                          >
                            {' '}
                            <i>
                              <BsPinterest className='icon' />{' '}
                            </i>
                          </a>
                          <a
                            href='https://www.instagram.com/queenscabinetrestoration/'
                            target='_blank'
                            rel='noreferrer'
                          >
                            {' '}
                            <i>
                              <BsInstagram className='icon' />{' '}
                            </i>{' '}
                          </a>
                        </div>
                      </div>
                      <div className='tp-header-menu-two'>
                        <div className='tp-main-menu tp-main-menu-two'>
                          <nav id='tp-mobile-menu'>
                            <ul>
                              <li>
                                <NavLink to='/'>Home</NavLink>
                              </li>
                              <li>
                                <NavLink to='/get-estimate'>Free Estimate </NavLink>
                              </li>
                              <li>
                                <NavLink to='/pricing'>Pricing</NavLink>
                              </li>
                              {/* <li>
                                 <NavLink to="/about">About</NavLink>
                               </li> */}
                              <li className='menu-item-has-children'>
                                <NavLink to='/servicesDetails'>Why QCR</NavLink>
                                <ul className='sub-menu'>
                                  <li>
                                    <NavLink to='/about'>About</NavLink>
                                  </li>
                                  <li>
                                    <NavLink to='/servicesDetails'>Why QCR?</NavLink>
                                  </li>
                                </ul>
                              </li>
                              {/* <li className="menu-item-has-children">
                                 <NavLink to="/services">Services </NavLink>
                                 <ul className="sub-menu">
                                   <li>
                                     <NavLink to="/services">Services</NavLink>
                                   </li>
                                   <li>
                                     <NavLink to="/servicesDetails">
                                       Services Details
                                     </NavLink>
                                   </li>
                                 </ul>
                               </li> */}
                              {
                                // Project Navbar
                              }
                              {/* <li className="menu-item-has-children">
                                 <NavLink to="/projects">Projects</NavLink>
                                 <ul className="sub-menu">
                                   <li>
                                     <NavLink to="/projects">Projects</NavLink>
                                   </li>
                                   <li>
                                     <NavLink to="/projectsDetails">
                                       Projects Details
                                     </NavLink>
                                   </li>
                                 </ul>
                               </li> */}
                              {
                                // BLOGS Navbar
                              }
                              {/* <li className="menu-item-has-children">
                                 <NavLink to="/blogSidebar">Blog</NavLink>
                                 <ul className="sub-menu">
                                   <li>
                                     <NavLink to="/blogSidebar">
                                       Blog Sidebar
                                     </NavLink>
                                   </li>
                                   <li>
                                     <NavLink to="/blogDetails">
                                       Blog Details
                                     </NavLink>
                                   </li>
                                 </ul>
                               </li> */}
                              <li>
                                <NavLink to='/contact'>Contact</NavLink>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className='tp-main-menu-two-btn'>
                          <Link to='/get-estimate' className='yellow-btn'>
                            Get Quote +
                          </Link>
                        </div>
                        {/* <!-- mobile menu activation --> */}
                        <div className='side-menu-icon d-xl-none text-end'>
                          <button
                            onClick={handleShow}
                            className='side-toggle border-0 bg-transparent'
                          >
                            <i>
                              {' '}
                              <FaBars className='bar_icon' />{' '}
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarContainer>

      <Sidebar show={show} handleClose={handleClose}></Sidebar>
    </>
  )
}

export default HomeTwoNavbar
