import HomeTwoSingleFeature from '../../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature'

const HomeTwoFeatures = () => {
  const featureData = [
    {
      icon: 'delivery-box',
      title: 'Online Estimation',
      description:
        'To get started, you just need to tell us a bit about your cabinets and what you want. You will get a free quote and before you know it, professional will be at your door.',
    },
    {
      icon: 'gift-box',
      title: 'Project Discount',
      description:
        'We provide cost-effective residential cabinet restoration services that always strive for exceptional results',
    },
    {
      icon: 'video-camera',
      title: 'Work Monitoring',
      description:
        'Every project is done right in front of your eyes. You can see the progress and make sure that everything is done according to your requirements.',
    },
    {
      icon: 'snowfall',
      title: 'Satisfied Service',
      description:
        'We are committed to providing the best service and the best results. We will work with you until you are completely satisfied.',
    },
  ]
  return (
    <>
      <section className='tp-feature-area-two pt-110 pb-90'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='section-title-wrapper-two mb-45 wow fadeInUp' data-wow-delay='.2s'>
                <h2 className='tp-section-title-two'>
                  We Serve Homes in {<br />}
                  <span>Nassau & Suffolk</span>
                </h2>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='tp-feature-title-desc mb-45 wow fadeInUp' data-wow-delay='.2s'>
                <p>
                  Elevate Your Home with Our Comprehensive Flooring, Tiling, and Cabinet Services.
                  Say Goodbye to Exposed Woodgrain and Hello to a Sleek, Modern Finish. And With Our
                  Fast Turnaround Time, Your Home Will Be Transformed in No Time.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-between align-items-xl-center'>
            <div className='col-xl-5 col-lg-6'>
              <div className='tp-feature-img mb-30 wow fadeInUp' data-wow-delay='.4s'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/homepage_imgs%2Fdownload%20(2).png?alt=media&token=26f90e45-3b4a-4f58-9723-6ebd658f1f13'
                  alt='img not found'
                />
              </div>
            </div>
            <div className='col-xl-6 col-lg-6'>
              <div className='row'>
                {featureData.map((feature, index) => (
                  <HomeTwoSingleFeature
                    key={index}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomeTwoFeatures
