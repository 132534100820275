import styled from 'styled-components';

export const NavbarContainer = styled.div`
  #header-nav-logo {
    max-height: 150px;
    position: relative;
    left: 8px;
  }

  .tp-header-logo-two-inner {
    justify-content: center;
    padding-left: 0;
  }

  .tp-header-area-two.header-sticky.sticky-menu {
    #header-nav-logo {
      max-height: 80px;
    }

    .tp-header-logo-two-inner {
      max-height: 80px;
    }
  }

  .tp-desktop-header-phone-icon-container i {
    animation: phone-icon-animate 1.5s ease-in-out infinite;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: phone-icon-animate;
  }

  @media (max-width: 991px) {
    .tp-header-logo-two-inner {
      max-height: 65px;
      max-width: 150px;
    }
    #header-nav-logo {
      max-height: 65px;
    }
  }
`;