import Form from '../../../../components/FormEmailJS'
import { WhyQCRContainer } from './style'

const Process = () => {
  return (
    <WhyQCRContainer>
      <section className='tp-feature-area-three pt-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div
                className='section-title-wrapper text-center mb-55 wow fadeInUp'
                data-wow-delay='.2s'
              >
                <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
                  Why QCF?
                </h5>
                <h2 className='tp-section-title heading-color-black'>
                  All The Reasons to Choose
                  <br /> The Best In The Business
                </h2>
              </div>
            </div>
          </div>
          <div className='container why-qcr'>
            <div className='why-qcr-body-container'>
              <ul>
                <li>
                  <p>
                    <strong>Expertise:</strong> Our team of experts has years of experience in the
                    flooring industry and are knowledgeable about all the latest trends and
                    technologies. We will help you select the perfect flooring solution for your
                    home and ensure that it is installed to the highest standard.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Quality Products:</strong>- We use only the best quality products in the
                    market, ensuring that your floors will last for years to come. Our tiles,
                    hardwoods, and other flooring options are designed to withstand everyday wear
                    and tear and will look great for years to come.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Affordable Prices: </strong>We believe that everyone deserves beautiful
                    floors, and that's why we offer affordable prices on all of our products and
                    services. Whether you're working with a tight budget or want to splurge on
                    something special, we have options to suit all budgets.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Customer Satisfaction: </strong>Our top priority is to ensure that our
                    customers are completely satisfied with the results of their flooring project.
                    We will work with you every step of the way to ensure that you are happy with
                    the final result.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Professionalism: </strong>Our team of experts is knowledgeable,
                    friendly, and professional. We will respect your home and work area and clean up
                    after ourselves once the project is complete.
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    <strong>We Provide EFFICIENT &amp; UNPAUSED Service:</strong>
                    We understand how important your kitchen is. We will work with precision and
                    unmatched efficiency to complete the project on time and give you back a
                    beautiful and new kitchen as fast as possible.
                  </p>
                </li>
              </ul>
            </div>
            <div className='why-qcr-form-container'>
              <Form />
            </div>
          </div>
        </div>
      </section>
    </WhyQCRContainer>
  )
}

export default Process
