import 'bootstrap/dist/js/bootstrap.js'
import ReactGA from 'react-ga4'
import { render } from 'react-snapshot'
import App from './App'
import './index.scss'

const rootElement = document.getElementById('root')
// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(<App />, rootElement);
// } else {
render(<App />, rootElement)
// }

try {
  setTimeout(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
    } else {
      ReactGA.initialize(process.env.REACT_APP_GA_TESTING_ID, {
        debug: true,
      })
    }
    window.gtag('config', 'AW-10810578634/fpbcCPWDnfoYEMqt8aIo', {
      phone_conversion_number: '(347) 551-9376',
    })
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, 2000)
} catch (err) {
  console.error(err)
}
