import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const PricingBreadcrumb = () => {
    return (
        <>
            <Breadcrumb title="Competitive" subTitle="Packages" smText=" Pricing"></Breadcrumb>
        </>
    );
};

export default PricingBreadcrumb;