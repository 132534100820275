import React from 'react';
import { Link } from 'react-router-dom';
import { GiCheckMark } from 'react-icons/gi';

const SpecialSinglePricingPackage = ({ image_url, title, activeclassName }) => {
   return (
     <>
       <div className="col-xl-4 col-md-6 mb-30">
         <div
           className="tp-pricing-two text-center bg-gray-light wow fadeInUp"
           data-wow-delay=".4s"
         >
           <div className="tp-pricing-two-img mb-10">
             <img src={image_url} className="img" alt="img not found" />
           </div>
           <div className="tp-pricing-two-content">
             <div
               className={
                 activeclassName
                   ? `tp-pricing-two-rate ${activeclassName}`
                   : "tp-pricing-two-rate"
               }
             >
               <h3>
                 {/* <span>$</span>  */}
                  <a href="tel:1(347)5519376">
                    <i className="flaticon-phone-call"></i>
                  </a>
               </h3>
             </div>
             <h4 className="tp-pricing-cat">{title}</h4>
             <div className="tp-pricing-two-btn mb-30">
               <Link to="/get-estimate" className="yellow-btn">
                 <i className="flaticon-enter"></i> Get Service
               </Link>
             </div>
             <div className="tp-pricing-two-list pb-50">
               <ul>
                 <li>
                   <i>
                     {" "}
                     <GiCheckMark />{" "}
                   </i>{" "}
                   Expert Consultation
                 </li>
                 <li>
                   <i>
                     {" "}
                     <GiCheckMark />{" "}
                   </i>{" "}
                   Free Inspection
                 </li>
                 <li>
                   <i>
                     {" "}
                     <GiCheckMark />{" "}
                   </i>{" "}
                   Free Estimate
                 </li>
                 <li>
                   <i>
                     {" "}
                     <GiCheckMark />{" "}
                   </i>{" "}
                   Premium {title}
                 </li>
               </ul>
             </div>
           </div>
         </div>
       </div>
     </>
   );
};

export default SpecialSinglePricingPackage;