import styled from "styled-components";

export const ContactFormWrapper = styled.div`
  .tp-contact-area, pb-120 {
    padding-top: 120px;
  }
`;


export const SuccessFormWrapper = styled.div`
  .success-msg-container {
    width: 300px;
    height: 300px;
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  #success-msg-text {
    margin-top: 14px;
    font-size: 18px;
    text-align: center;
    color: #000;
  }

  #thank-you-text {
    font-size: 30px;
  }

  #success-msg-note {
    font-size: 14px;
  }

  .icon {
    font-size: 100px;
    display: block;
  }
`;
