import styled from "styled-components";

export const ServicesDetailsFaqArea = styled.section`
  .row-container {
    padding-bottom: 40px;
  }
  .why-choose-us {
    margin-top: 40px;
  }
  .why-choose-us-bullet {
    font-weight: 700;
  }
`;