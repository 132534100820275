import emailjs from '@emailjs/browser'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { BsInstagram, BsPinterest } from 'react-icons/bs'
import { FaEnvelopeOpen, FaFacebookF, FaMapMarkerAlt, FaPaperPlane } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { gtag_report_conversion } from '../../../utils/googleNumberTracker'
import { SuccessNewsletterWrapper } from './styles'
const Footer = ({ landingPage }) => {
  const [email, setEmail] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    setButtonDisabled(true)
    const subParams = {
      subEmail: email,
      subUrl: window.location.pathname,
    }

    try {
      await emailjs.send('service_67ql8o4', 'NewSubscribereTemplateID', subParams)
      setFormSubmitted(true)
      setButtonDisabled(false)
      ReactGA.event({
        category: 'joined_newsletter',
        action: 'subscribed_to_newsletter',
      })
    } catch (error) {
      console.log(error)
    }
  }

  const renderForm = () => (
    <div className='tp-footer-subscribe-area-two position-relative pt-100'>
      <div className='container'>
        <div className='tp-footer-subscribe-bg-two theme-yellow-bg pt-30 pb-20 z-index pl-60 pr-60'>
          <div className='row align-items-center'>
            <div className='col-xl-5 col-lg-4'>
              <div className='tp-footer-subscribe'>
                <h3 className='tp-footer-subscribe-title'>Join QCR's Newsletter</h3>
              </div>
            </div>
            <div className='col-xl-7 col-lg-8'>
              {formSubmitted ? (
                <SuccessNewsletterWrapper>
                  <p>* Thanks for joining the QCR Family! *</p>
                </SuccessNewsletterWrapper>
              ) : (
                <div className='tp-footer-subscribe-form'>
                  <form action='#' className='p-0'>
                    <div className='tp-footer-subscribe-form-field mb-10'>
                      <input
                        type='text'
                        placeholder='Email Address'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <i>
                        <FaPaperPlane />
                      </i>
                    </div>
                    <div className='tp-footer-subscribe-form-btn mb-10'>
                      <button
                        disabled={buttonDisabled}
                        type='submit'
                        className='theme-btn text-white'
                        onClick={handleSubmit}
                      >
                        <i className='flaticon-enter'></i> Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <>
      <footer className='theme-dark-bg'>
        {!landingPage && (
          <>
            {renderForm()}
            <div className='tp-footer-area-two pt-80 pb-50'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-3 col-sm-6'>
                    <div
                      className='tp-footer-widget footer-col-1 mb-30 wow fadeInUp'
                      data-wow-delay='.3s'
                    >
                      <div className='tp-footer-info'>
                        <div className='tp-footer-info-logo mb-35'>
                          <Link to='/'>
                            <img
                              src='assets/img/logo/logo-white.png'
                              className='img-fluid'
                              alt='img not found'
                            />
                          </Link>
                        </div>
                        <h4 className='mb-15'>
                          <a onClick={() => gtag_report_conversion} href='tel:1(347)5519376'>
                            (347) 551-9376
                          </a>
                        </h4>
                        <h6 className='mb-15'>
                          {' '}
                          <i>
                            {' '}
                            <FaEnvelopeOpen />{' '}
                          </i>
                          <a href='mailto:queenscabinetrestoration@gmail.com'>
                            queenscabinetrestoration@gmail.com
                          </a>
                        </h6>
                        <h6 className='mb-20'>
                          {' '}
                          <i>
                            {' '}
                            <FaMapMarkerAlt />{' '}
                          </i>{' '}
                          88-30 197 Street. Queen's, New York
                        </h6>
                        <div className='tp-footer-info-social'>
                          <a
                            href='https://www.facebook.com/queenscabinetrestoration/'
                            target='_blank'
                            rel='noreferrer'
                          >
                            <i>
                              <FaFacebookF className='icon' />{' '}
                            </i>
                          </a>
                          <a
                            href='https://www.pinterest.com/queenscabinetrestoration/'
                            target='_blank'
                            rel='noreferrer'
                          >
                            <i>
                              <BsPinterest className='icon' />{' '}
                            </i>
                          </a>
                          <a
                            href='https://www.instagram.com/queenscabinetrestoration/'
                            target='_blank'
                            rel='noreferrer'
                          >
                            <i>
                              <BsInstagram className='icon' />{' '}
                            </i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-sm-6'>
                    <div
                      className='tp-footer-widget footer-col-2 mb-30 wow fadeInUp'
                      data-wow-delay='.6s'
                    >
                      <h4 className='tp-footer-widget-title mb-35'>Our Services</h4>
                      <ul>
                        <li>
                          <a href='/servicesDetails'>Cabinet Restoration</a>
                        </li>
                        <li>
                          <a href='/servicesDetails'>Cabinet Refacing</a>
                        </li>
                        <li>
                          <a href='/servicesDetails'>Cabinet Installation</a>
                        </li>
                        <li>
                          <a href='/servicesDetails'>Cabinet Painting</a>
                        </li>
                        <li>
                          <a href='/servicesDetails'>Cabinet Refinishing</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-sm-6">
                 <div
                   className="tp-footer-widget footer-col-3 mb-30 wow fadeInUp"
                   data-wow-delay=".9s"
                 >
                   <h4 className="tp-footer-widget-title mb-35">
                     Recent Announcements
                   </h4>
                   <div className="tp-footer-news">
                     <div className="tp-footer-news-single mb-15">
                       <h6>
                         <Link to="/blogDetails">
                           Orci magna pede, quisque sociis integer litora netus
                         </Link>
                       </h6>
                       <span>Jun 02, 2021</span>
                     </div>
                     <div className="tp-footer-news-single">
                       <h6>
                         <Link to="/blogDetails">
                           Congue morbi elit dictumst socio sit mauris congue
                           sed.
                         </Link>
                       </h6>
                       <span>Jun 02, 2021</span>
                     </div>
                   </div>
                 </div>
               </div> */}
                  <div className='col-lg-3 col-sm-6'>
                    <div
                      className='tp-footer-widget footer-col-3 mb-30 wow fadeInUp'
                      data-wow-delay='.9s'
                    >
                      <h4 className='tp-footer-widget-title mb-35'>Recent Special's</h4>
                      <div className='tp-footer-news'>
                        <div className='tp-footer-news-single'>
                          <h6>Referral Program: Get a $200 on us for every referral</h6>
                          <span>Oct 1st, 2022</span>
                        </div>
                        <div className='tp-footer-news-single mb-15'>
                          <h6>Mothers Day Special: 10% Off All Services</h6>
                          <span>May 08, 2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-sm-6'>
                    <div
                      className='tp-footer-widget footer-col-4 mb-30 wow fadeInUp'
                      data-wow-delay='1.2s'
                    >
                      <h4 className='tp-footer-widget-title mb-40'>Instagram</h4>
                      <div className='tp-footer-insta'>
                        <a
                          href='https://www.instagram.com/queenscabinetrestoration'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            src='https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/Text%20Me%20One%20(4).png?alt=media&token=bf9560d3-5c52-4a40-a8c7-e20cacd0f9ff'
                            className='img-fluid'
                            alt='img not found'
                          />
                        </a>
                        <a
                          href='https://www.instagram.com/queenscabinetrestoration'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            src='https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/Text%20Me%20One%20(5).png?alt=media&token=7fb24a21-4734-4d57-8b7e-fa0e288df5b5'
                            className='img-fluid'
                            alt='img not found'
                          />
                        </a>
                        <a
                          href='https://www.instagram.com/queenscabinetrestoration'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            src='https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/Text%20Me%20One%20(6).png?alt=media&token=8b050cfe-2321-4000-bcea-8deae85f8ae8'
                            className='img-fluid'
                            alt='img not found'
                          />
                        </a>
                        <a
                          href='https://www.instagram.com/queenscabinetrestoration'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            src='https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/Text%20Me%20One%20(7).png?alt=media&token=1ceb7fd2-6c39-4aa9-8bfb-f02b17223634'
                            className='img-fluid'
                            alt='img not found'
                          />
                        </a>
                        <a
                          href='https://www.instagram.com/queenscabinetrestoration'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            src='https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/Text%20Me%20One%20(8).png?alt=media&token=a60ff5b4-8dc1-4169-b8bb-fede73e6bb4d'
                            className='img-fluid'
                            alt='img not found'
                          />
                        </a>
                        <a
                          href='https://www.instagram.com/queenscabinetrestoration'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            src='https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/Text%20Me%20One%20(10).png?alt=media&token=79ee1ff7-2fbd-4567-b698-48f34f35cc0b'
                            className='img-fluid'
                            alt='img not found'
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='tp-copyright-area-two bg-green-light z-index pt-30 pb-30'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='tp-copyright tp-copyright-two text-center'>
                  <p className='m-0'>
                    Copyright ©2022 <span>Queens Cabinet Restoration Inc</span>. All Rights Reserved
                    Copyright
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
