import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const ServicesDetailsBreadcrumb = () => {
   return (
     <>
       <Breadcrumb
         title="Why"
         subTitle="Choose Us?"
         smText="Service Details"
       ></Breadcrumb>
     </>
   );
};

export default ServicesDetailsBreadcrumb;