import styled from "styled-components";

export const CTAContainer = styled.div`
  .tp-cta-two {
    height: 254px;
  }

  @media (max-width: 1000px) {
    .tp-cta-two {
      height: auto;
    }
  }

`;