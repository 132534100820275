import styled from "styled-components"

export const HeroSectionContainer = styled.div`
  .home_two_slide_bg {
    background: url(${(props) => props.$bgUrl}) no-repeat center center;
    @media screen and (max-width: 767px) {
      background: url(${(props) => props.$bgMobileUrl}) no-repeat center center;
    }
  }

  .tp-slider-two {
    background: rgb(44 44 44 / 30%);
    max-width: 1100px;
    margin: auto;
    padding: 64px 0;
    .tp-slider-title-two {
      color: #fff;
    }

    .tp-slider-subtitle-two {
      color: #fed20a;
    }
  }
  @media (max-width: 780px) {
    .tp-slider-two {
      max-width: auto;
    }
  }
`;