import 'react-image-gallery/styles/css/image-gallery.css'
import styled from 'styled-components'

export const HeroWrapper = styled.div`
  .tp-slider {
    width: 100%;
  }
  
  .tp-slider-title,
  .tp-slider-subtitle {
    color: #000;
  }

  .tp-slider-title span {
    color: #07ad5b;
  }

  .tp-slider-h4 {
    h4 {
      color: #000;
    }
  }

  .hero-data-container {
    display: flex;
    min-height: 500px;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 450px;
    max-width: 540px;
    height: auto;
    overflow: hidden;
    width: 100%;
    img.image-gallery-image {
      max-height: 450px;
      height: 100%;
      width: auto;

    }
  }

  .btn-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    .tp-slider-wrapper {
      padding-top: 0;
    }

    .hero-data-container {
      flex-direction: column;
    }
  }
`
