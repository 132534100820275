import styled from "styled-components";

export const HeroWrapper = styled.div`

  .tp-slider {
    width: 100%;
  }
  
  .tp-slider-title,
  .tp-slider-subtitle {
    color: #000;
  }

  .tp-slider-title span {
    color: #07ad5b;
  }

  .tp-slider-h4 {
    h4 {
      color: #000;
    }
  }

  .hero-data-container {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    .tp-slider-wrapper {
      padding-top: 0;
    }

    .hero-data-container {
      flex-direction: column;
    }
  }
`;
