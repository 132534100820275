import styled from "styled-components";

export const SuccessNewsletterWrapper = styled.div`
  text-align: center;

  .tp-footer-subscribe-title {
    text-align: center;
  }
  
`;

