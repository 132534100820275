import React from 'react';
import HomeTwoCtaSingleItem from '../../../../components/HomeTwoCtaSingleItem/HomeTwoCtaSingleItem';
import {CTAContainer} from './styles'

const HomeTwoCtaArea = () => {
   const ctaData = [
     {
       icon: "badge",
       title: "Expert Restorations",
       details:
         "We draw on experience from hundreds of past projects to bring your home back to life, with the best materials and craftsmanship.",
     },
     {
       icon: "booking",
       title: "Quick & Easy Process",
       details: "We make the process of getting a new home as easy as possible. Call or submit a request for a free estimate today.",
     },
     {
       icon: "happy",
       title: "100% Satisfaction",
        details:
          "We are committed to providing the best service and the best results. We will work with you until you are completely satisfied.",
     },
   ];
   return (
     <CTAContainer>
       <section className="tp-cta-area-two">
         <div className="tp-cta-area-two-bg">
           <div className="row">
             <HomeTwoCtaSingleItem ctaData={ctaData[0]} />
             <HomeTwoCtaSingleItem ctaData={ctaData[1]} />
             <HomeTwoCtaSingleItem ctaData={ctaData[2]} />
           </div>
         </div>
       </section>
     </CTAContainer>
   );
};

export default HomeTwoCtaArea;