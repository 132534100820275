import React from "react";
import Footer from "../../components/shared/Footer/Footer";
import HomeTwoNavbar from "../HomeTwo/HomeTwo/HomeTwoNavbar/HomeTwoNavbar";
import NotFound from "./index.tsx";
import { NotFoundPageWrapper } from "./style";
const HomeTwo = () => {
  return (
    <NotFoundPageWrapper>
      {/* <HomeTwoNavbar /> */}
      <NotFound />
      {/* <Footer /> */}
    </NotFoundPageWrapper>
  );
};

export default HomeTwo;
