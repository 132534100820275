import SingleAppointmentFeature from '../../../../components/SingleAppointmentFeature/SingleAppointmentFeature'

const AppointmentFeatures = () => {
  const features = [
    {
      icon: 'booking',
      title: 'Request Service',
      description:
        'Get started by filling up the form below and member of our dedicated team will get back to you as soon',
    },
    {
      icon: 'delivery-box',
      title: 'Confirm Project Details',
      description:
        'We will discuss your dream home and we will provide you with a competitive and affordable quote',
    },
    {
      icon: 'cleaning',
      title: 'Start Restoration',
      description:
        'Our masterclass team will start the restoration process and will be in touch with you every step of the way',
    },
    {
      icon: 'boy',
      title: 'Enjoy Your New Home',
      description:
        'We have a 100% success ratio of completing each restoration job wihing with the given timeline. (~1 Week)',
    },
  ]
  return (
    <>
      <section className='tp-feature-area-three pt-120 pb-80'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div
                className='section-title-wrapper text-center mb-55 wow fadeInUp'
                data-wow-delay='.2s'
              >
                <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
                  Our Process
                </h5>
                <h2 className='tp-section-title mb-25 heading-color-black'>
                  100% Hassle-Free
                  <br /> Personalized Service For You
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            {features.map((feature, index) => (
              <SingleAppointmentFeature
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default AppointmentFeatures
