import styled from "styled-components";

export const BodyWrapper = styled.div`
  .container {
    display: flex;

    @media (max-width: 991px) {
      flex-direction: column;
      .body-container {
        order: 2;
      }
    }
  }

  .body-container {
    p {
      color: #000;
    }
    padding: 0 15px 0 0;
  }
`;