import Form from '../../../../components/FormEmailJS'
import { BodyWrapper } from './style'
const BodyComponent = () => {
  return (
    <BodyWrapper>
      <section className='tp-about-area position-relative pt-20 pb-20 fix'>
        <div className='container'>
          <div className='body-container'>
            <p>Welcome to Queens Cabients & Flooring Co., where your floors are our passion!</p>{' '}
            <p>
              We understand the importance of having beautiful and durable floors in your home.{' '}
            </p>
            <p>
              Your floors are the foundation of your interior design, and they need to be able to
              withstand the test of time.{' '}
            </p>
            <p>
              Over time, even the most stunning of floors can start to show signs of wear and tear,
              leaving you with a dull and outdated space.
            </p>
            <p>
              But don't worry, we've got you covered! Our comprehensive flooring solutions cater to
              every style and budget, and our team of experts is here to help you find the perfect
              solution for your home.
            </p>
            <p>
              Whether you choose to refinish your existing floors or opt for a complete flooring
              upgrade, we offer a wide range of services, including:
            </p>
            <p>
              <strong>Tiling:</strong> From ceramic to porcelain, and everything in between, our
              tiling experts have the knowledge and experience to bring your vision to life.{' '}
            </p>
            <p>
              <strong>Hardwood flooring:</strong> Nothing adds warmth and elegance to a home like
              hardwood floors. Our hardwood flooring services range from refinishing and restoration
              to full installations.
            </p>{' '}
            <p>
              <strong>Porcelain flooring: </strong>Porcelain is a durable and versatile option that
              can mimic the look of other flooring materials, including stone and wood.{' '}
            </p>
            <p>
              <strong>Marble flooring: </strong>Marble is a luxurious option that adds
              sophistication and beauty to any space. Our marble flooring services include
              installation, maintenance, and repair.
            </p>
            <p>
              With the option to refinish your existing floors, you can save up to 80% of the cost
              of a full renovation.{' '}
            </p>{' '}
            <p>
              That's a savings of thousands of dollars! <p></p>So why wait? Are you ready to start
              your flooring project?
            </p>{' '}
            <p>
              <strong>
                Fill out our form or give us a call at{' '}
                <a href='tel:1(347)5519376'>(347) 551-9376</a> to get started today.
              </strong>
            </p>
          </div>
          <div className='formContainer'>
            <Form />
          </div>
        </div>
      </section>
    </BodyWrapper>
  )
}

export default BodyComponent
