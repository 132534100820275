import styled from "styled-components";

export const WhyQCRContainer = styled.div`
  .why-qcr {
    display: flex;
    & > div {
      width: 100%;
    }
  }
  .why-qcr-body-container {
    p {
      color: #000;
    }
    li {
      margin-left: 20px;
      list-style: none;
    }
  }

  .why-qcr-form-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  @media (max-width: 778px) {
    .why-qcr {
      flex-direction: column;
    }
    .why-qcr-body-container {
      order: 2;
      padding-top: 50px;
      li {
        margin-left: auto;
      }
    }
  }
`;