import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const ContactBreadcrumb = () => {
   return (
      <>
         <Breadcrumb title="Contact" subTitle="Us" smText='Contact-us'></Breadcrumb>
      </>
   );
};

export default ContactBreadcrumb;