import React from 'react';
import Form from "../../../components/FormEmailJS";
import  { FaqWrapper } from "./style";
const FAQ = () => {
    return (
      <FaqWrapper>
        <section className="tp-faq-area bg-gray-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="form">
                  <Form />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tp-faq-text pt-120">
                  <div className="section-title-wrapper-two mb-45">
                    <h2 className="tp-section-title-two">
                      Frequently Asked <span>Restoration</span> Questions
                    </h2>
                  </div>
                  <div className="accordion" id="accordionExample">
                    {faqData.map((faq, index) => (
                      <div key={index} className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`heading${faq.id}`}
                        >
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${faq.id}`}
                            aria-expanded={index === 0 ? true : false}
                            aria-controls={`#collapse${faq.id}`}
                          >
                            {faq.title}
                          </button>
                        </h2>
                        <div
                          id={`collapse${faq.id}`}
                          className={`accordion-collapse collapse ${
                            index === 0 ? "show" : ""
                          }`}
                          aria-labelledby={`heading${faq.id}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">{faq.content}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </FaqWrapper>
    );
};

export default FAQ;


 const faqData = [
   {
     id: "One",
     title: "How long does a cabinet refinishing take?",
     content: ` Refinishing your bathroom or kitchen cabinets in an
          average-sized room takes around 3 days. You won't
          be able to use your kitchen for several days during
          this time, so plan on eating out.`,
   },
   {
     id: "Two",
     title: `Will my house be dusty and dirty from the sanding?`,
     content: `Absolutely not. We will seal all work ares so thatdust doesn’t spread throught the house.`,
   },
   {
     id: "Three",
     title: `Is high gloss more protective and easier to clean up?`,
     content: `High gloss is more protective than all the other
          finishes such as satin, matte, and sami gloss. Cleanup
          is about the same.`,
   },
   {
     id: "Four",
     title: `What finish do you recommend?`,
     content: `In older cabinet models,
          high gloss were used most often but satin finish gives
          the most modern look.`,
   },
 ];