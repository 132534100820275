import { ProcessContainer } from './style'
const Process = () => {
  return (
    <ProcessContainer>
      <section className='tp-feature-area-three pt-120 pb-80'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div
                className='section-title-wrapper text-center mb-55 wow fadeInUp'
                data-wow-delay='.2s'
              >
                <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
                  Our Process
                </h5>
                <h2 className='tp-section-title heading-color-black'>
                  100% Hassle-Free
                  <br /> Premium Tiling & Flooring
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='container'>
              <div className='body-part-2-container'>
                <p>
                  <strong>Queens Cabinets & Flooring Co.</strong> are the tiling and flooring
                  professionals that will work with you to give you the best home improvement
                  experience ever!&nbsp;
                </p>
                <p>
                  With our 11+ years of experience, you can rest easy knowing you will be getting
                  the best in the business. We have experienced all the in&rsquo;s and out&rsquo;s
                  of tiling and flooring.&nbsp;
                </p>
              </div>
            </div>
          </div>
          <div className='process-step-container'>
            <div className='process-steps-image-container'>
              <div className='process-steps-graphics'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/tilingnflooringpage.png?alt=media&token=e8b09c02-da3a-46db-8660-4bd1fc9a2783'
                  alt='img not found'
                />
              </div>
              <div className='process-steps-graphics-bg'>
                <img src='assets/img/team/team-shape-1.jpg' alt='img not found' />
              </div>
            </div>
            <div className='process-steps-1'>
              <h3>Part 1: Expert Consultation</h3>
              <p>
                Our first step is to schedule a consultation with one of our experts to assess your
                current flooring situation and discuss your design preferences. During this initial
                meeting, we will take measurements and provide you with a detailed quote. We will
                also help you select the best flooring options to suit your style, budget, and
                functional needs. nbsp;
              </p>
              <p>
                Bring all the questions you want to ask us and get all the confidence you need to
                move forward with your flooring project.&nbsp;
              </p>
            </div>
          </div>
          <div className='process-step-container'>
            <div className='process-steps-2'>
              <h3>Part 2: Time to Start the Restoration!</h3>
              <p>
                Once you have chosen the perfect flooring solution for your home, our team of
                experts will get to work.
              </p>
              <ul>
                <li className='list-item'>
                  <p>
                    For <strong>Tiling</strong>:&nbsp;
                  </p>
                </li>
              </ul>
              <p className='ml-20'>
                For tiling installation, we will remove any existing flooring, prepare the surface,
                and lay the tiles with precision. Our tiling experts will also provide grouting and
                sealing services to ensure that your tiles are protected from moisture and other
                elements.
              </p>
              {/* <p className="ml-20">
                 Once the protective coat is dry, we will again sand all
                 surfaces with the finest 240 grit sandpaper. Only to apply the
                 final coat of polyurethane.
               </p> */}
              <ul>
                <li className='list-item'>
                  <p>
                    For <strong>Hardwood Flooring</strong>:
                  </p>
                </li>
              </ul>
              <p className='ml-20'>
                For hardwood flooring installation, our team will first remove any existing flooring
                and prepare the surface. We will then install the new hardwood flooring, sand and
                finish it to a high-quality standard. Our hardwood flooring experts will also
                provide staining services to match your desired color and finish.
              </p>
              <p>
                For other popular flooring types such as porcelain, marble, and laminate, we will
                follow the same installation process to ensure the best possible results. We will
                remove any existing flooring, prepare the surface, and install the new flooring to
                the highest standard.{' '}
              </p>
              <p>
                Once the installation is complete, we will perform a final inspection to ensure that
                you are 100% satisfied with the result. So why wait? Let us help you create the
                beautiful and durable floors of your dreams. Fill out our form or give us a call at{' '}
                <strong>
                  <a href='tel:1(347)5519376'>(347) 551-9376</a>
                </strong>{' '}
                to schedule your consultation today.
              </p>
            </div>
            <div className='process-steps-image-container'>
              <div className='process-steps-graphics-2'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/todoapp-b01b7.appspot.com/o/tilingnflooringpage5.png?alt=media&token=4351d7c4-5d13-4c7c-b9a0-fba89ea023d8'
                  alt='img not found'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ProcessContainer>
  )
}

export default Process
