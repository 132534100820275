import React, { useState } from 'react';
import emailjs from "@emailjs/browser";
import ReactGA from "react-ga4";

const CtaArea = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [service, setService] = useState("Choose Service");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      const LeadParams = {
        leadName: name,
        leadPhone: phone,
        leadService: service,
        leadUrl: window.location.pathname,
      };
      try {
        await emailjs.send(
          "service_67ql8o4",
          "LeadEmailRequestService",
          LeadParams
        );
        setFormSubmitted(true);
        ReactGA.event({
          category: "generate_lead",
          action: "short_form_submitted",
        });
      } catch (error) {
        console.log(error);
      }
    };
   return (
     <>
       <section className="tp-appoint-cta-area yellow-dark-bg pt-80 pb-65">
         <div className="container">
           <div className="row align-items-center custom-mar-20">
             {formSubmitted ? (
               <>
                 <div className="col-lg-12">
                   <div className="tp-appoint-cta-content text-center">
                     <h2 className="tp-appoint-cta-title">
                       Thank you for your interest!
                     </h2>
                     <p className="tp-appoint-cta-text">
                       We will be in touch shortly.
                     </p>
                   </div>
                 </div>
               </>
             ) : (
               <>
                 <div className="col-xl-2 col-lg-12 custom-pad-20">
                   <div
                     className="tp-appoint wow fadeInUp"
                     data-wow-delay=".1s"
                   >
                     <h4 className="tp-appoint-title">
                       Request FREE Consultation
                     </h4>
                   </div>
                 </div>
                 <div className="col-xl-8 col-lg-9 custom-pad-20">
                   <div className="row align-items-center custom-mar-20">
                     <div className="col-lg-4 custom-pad-20">
                       <div
                         className="tp-appoint wow fadeInUp"
                         data-wow-delay=".3s"
                       >
                         <input
                           type="text"
                           placeholder="Full Name"
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                           required
                         />
                       </div>
                     </div>
                     <div className="col-lg-4 custom-pad-20">
                       <div
                         className="tp-appoint wow fadeInUp"
                         data-wow-delay=".5s"
                       >
                         <input
                           type="text"
                           placeholder="Phone Number"
                           value={phone}
                           onChange={(e) => setPhone(e.target.value)}
                           required
                         />
                       </div>
                     </div>
                     <div className="col-lg-4 custom-pad-20">
                       <div
                         className="tp-appoint select-field-arrow wow fadeInUp"
                         data-wow-delay=".7s"
                       >
                         <select
                           onChange={(e) => setService(e.target.value)}
                           value={service}
                         >
                           <option disabled value="Choose Service">
                             Choose Service
                           </option>
                           <option value="cabinet-refinishing">
                             Cabinet Refinishing
                           </option>
                           <option value="cabinet-restoration">
                             Cabinet Restoration
                           </option>
                           <option value="wood-floors">Wood Floors</option>
                           <option value="tiling">Tiling</option>
                           <option value="painting">Painting</option>
                           <option value="other">Other</option>
                         </select>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="col-xl-2 col-lg-3 custom-pad-20">
                   <div
                     className="tp-appoint text-end wow fadeInUp"
                     data-wow-delay=".9s"
                   >
                     <button
                       type="submit"
                       onClick={handleSubmit}
                       className="theme-btn text-white"
                     >
                       <i className="flaticon-enter"></i> Submit Now
                     </button>
                   </div>
                 </div>
               </>
             )}
           </div>
         </div>
       </section>
     </>
   );
};

export default CtaArea;