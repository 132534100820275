import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper'
import 'swiper/css/pagination'
import AppointmentSingleFeedback from '../../../../components/AppointmentSingleFeadback/AppointmentSingleFeadback'
import { AppointmentFeadbackContainer } from './styles'
SwiperCore.use([Navigation, Pagination])

const AppointmentFeedback = () => {
  const feedbackData = [
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2FResize-content%20(15).png?alt=media&token=c452d22e-6f59-4927-93d0-fa340983d3cc',
      title: 'Cabinet Refinishing',
      name: 'Kate Kadet',
      review:
        'We’ve already used them for two projects, because we had such a positive experience the first time! Our Kitchen was badly damaged during the storm last fall, and they were quick, communicative, and reasonable. ',
    },
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2FResize-content%20(16).png?alt=media&token=88695ad3-e008-413b-8f7b-d61238470e99',
      title: 'Cabinet Refinishing',
      name: 'Jean Pantina',
      review:
        'Zack is very communicative, professional and a pleasure to work with. Details were worked through and ironed out from the outset of the project. His assistant, Miguel, was very prompt & professional throughout the project.',
    },
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2FResize-content%20(17).png?alt=media&token=f0508e1c-8b3a-4929-84ee-bb2acf3a8c69',
      title: 'Cabinet Refinishing',
      name: 'Robert Alicea',
      review:
        'This is, by far, the very best construction experience I have ever had. I had my kitchen cabinets refinished, and from the introduction of materials, to the quality of workmanship, Queens Cabinet Restoration Inc was 5 stars all the way.',
    },
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2FResize-content%20(18).png?alt=media&token=b7336c85-c936-4d12-9434-0318902112d1',
      title: 'Cabinet Refinishing',
      name: 'Kathy Swensen',
      review:
        'From start to finish we received great customer service. The price we were quoted was the price we paid. Zack and his entire team were great. I highly recommend them',
    },
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/queenscabinetandflooring.appspot.com/o/reviews%2FResize-content%20(19)%20(1).png?alt=media&token=370160a1-59e1-4eba-85ac-e2a0763ebe9b',
      title: 'Bathroom Cabinets',
      name: 'Daniel Young',
      review:
        'Zack and his team did a great job. Extremely happy with there workmanship, punctuality and pricing. I look forward to using zack again for any renovations I do in the future. Highly recommended.',
    },
  ]
  return (
    <AppointmentFeadbackContainer>
      <section className='tp-testimonial-area-three pt-120 pb-190'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div
                className='section-title-wrapper text-center mb-55 wow fadeInUp'
                data-wow-delay='.2s'
              >
                <h5 className='tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black'>
                  Customer Feedback
                </h5>
                <h2 className='tp-section-title heading-color-black'>
                  Our philosophy is to make our clients happy.
                </h2>
              </div>
            </div>
          </div>
          <div className='tp-testimonial-three-shadow common-dots'>
            <div className='tp-testimonial-three-active swiper-container'>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                style={{ paddingBottom: '80px' }}
                className='swiper-wrapper'
                pagination={{ clickable: true }}
                autoplay={{ delay: 6000 }}
                breakpoints={{
                  550: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
                navigation={{
                  nextEl: '.service-button-next',
                  prevEl: '.service-button-prev',
                }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                {feedbackData.map((item, index) => (
                  <SwiperSlide key={index}>
                    <AppointmentSingleFeedback
                      imageUrl={item.imageUrl}
                      title={item.title}
                      name={item.name}
                      review={item.review}
                    />
                  </SwiperSlide>
                ))}

                <div className='swiper-service-pagination slide-dots'></div>
              </Swiper>
            </div>

            <div className='swiper-pagination-testimonial slide-dots '></div>
          </div>
        </div>
      </section>
    </AppointmentFeadbackContainer>
  )
}

export default AppointmentFeedback
