'use client';
import styled from "styled-components";

export const LoadingScreenWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100vh;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
`;