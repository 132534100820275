import React from 'react';
import { ProcessContainer } from "./style";
const Process = () => {
   return (
     <ProcessContainer>
       <section className="tp-feature-area-three pt-120 pb-80">
         <div className="container">
           <div className="row">
             <div className="col-12">
               <div
                 className="section-title-wrapper text-center mb-55 wow fadeInUp"
                 data-wow-delay=".2s"
               >
                 <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                   Our Process
                 </h5>
                 <h2 className="tp-section-title heading-color-black">
                   100% Hassle-Free
                   <br /> Premium Cabinet Restoration
                 </h2>
               </div>
             </div>
           </div>
           <div className="row">
             <div className="container">
               <div className="body-part-2-container">
                 <p>
                   <strong>Queens Cabinet Restoration</strong> are the cabinet
                   professionals that will work with you to give you the best
                   home improvement experience ever!&nbsp;
                 </p>
                 <p>
                   With our 11+ years of experience, you can rest easy knowing
                   you will be getting the best in the business. We have
                   experienced all the in&rsquo;s and out&rsquo;s of cabinet
                   restoration.&nbsp;
                 </p>
               </div>
             </div>
           </div>
           <div className="process-step-container">
             <div className="process-steps-image-container">
               <div className="process-steps-graphics">
                 <img src="assets/img/landing/img-1.png" alt="img not found" />
               </div>
               <div className="process-steps-graphics-bg">
                 <img
                   src="assets/img/team/team-shape-1.jpg"
                   alt="img not found"
                 />
               </div>
             </div>
             <div className="process-steps-1">
               <h3>Part 1: Expert Consultation</h3>
               <p>
                 Our first step of every restoration project begins with you. We
                 will go through a detailed review of your current cabinet
                 condition and discuss the possibilities. This is an important
                 step in the process because you will get to leverage the
                 experience and knowledge we have picked up from being in this
                 industry for over a decade!&nbsp;
               </p>
               <p>
                 Bring all the questions you want to ask us and get all the
                 confidence you need to move forward with your cabinet
                 restoration.&nbsp;
               </p>
             </div>
           </div>
           <div className="process-step-container">
             <div className="process-steps-2">
               <h3>Part 2: Time to Start the Restoration!</h3>
               <p>
                 Every project begins with stripping the previous finish,
                 whether it&apos;s a shellac, a lacquer, a water-based, a
                 polyurethane, a varnish, a penetrating oil, or a paint
                 finish&hellip;we have the proper solution to strip it down to
                 its original state of barewood. We then fill up any holes and
                 smooth out any imperfections on the wood surface while
                 sanding.&nbsp;
               </p>
               <ul>
                 <li className="list-item">
                   <p>
                     For <strong>Refinishing/Restaining Cabinets</strong>:&nbsp;
                   </p>
                 </li>
               </ul>
               <p className="ml-20">
                 After we have the surface as smooth as possible, we will
                 carefully apply the stain you have picked out. Once all the
                 stain is cured we will apply 2 protective coats of
                 polyurethane. (...in other words, furniture wax)
               </p>
               <p className="ml-20">
                 Once the protective coat is dry, we will again sand all
                 surfaces with the finest 240 grit sandpaper. Only to apply the
                 final coat of polyurethane.
               </p>
               <ul>
                 <li className="list-item">
                   <p>
                     For <strong>Painting Cabinets</strong>:
                   </p>
                 </li>
               </ul>
               <p className="ml-20">
                 To paint the cabinets is a much less rigorous process.&nbsp;
               </p>
               <p className="ml-20">
                 After stripping the protective coat &mdash; we will fill up and
                 smoothen out any imperfections. Once all the surfaces are
                 prepped and ready, we will spray a coat of primer for proper
                 adhesion. Then we will lay two coats of paint that you have
                 chosen for your cabinets.
               </p>
             </div>
             <div className="process-steps-image-container">
               <div className="process-steps-graphics-2">
                 <img src="assets/img/landing/img-3.png" alt="img not found" />
               </div>
             </div>
           </div>
         </div>
       </section>
     </ProcessContainer>
   );
};

export default Process;