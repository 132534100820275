import styled from "styled-components";

export const WarrantyContainer = styled.div`
  #disclaimer-note {
    font-size: 12px;
  }
  .discount-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }

  #discount {
    color: #07ad5b;
  }

  #timer-container {
    color: #FF5252;
  }
`;  